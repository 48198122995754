import React, { useState, useEffect, useRef } from "react";
import "./component.css"; // Import your CSS file
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { url, clientpoints } from "../../../../../lib/lib.js";

function Component({ roleObject = "", role = "" }) {
  const sidebarRef = useRef();
  const { hotelId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeDrop, setActiveDrop] = useState("");
  const isMastersDropdownOptionActive = () => {
    return (
      pathname.includes("masters/property-types") ||
      pathname.includes("masters/room-types") ||
      pathname.includes("masters/pricing-types") ||
      pathname.includes("masters/property-amenities") ||
      pathname.includes("masters/room-amenities") ||
      pathname.includes("masters/policies") ||
      pathname.includes("masters/faqs")
    );
  };
  const isResourcesDropdownOptionActive = () => {
    return pathname.includes("resources/areas") || pathname.includes("resources/properties") || pathname.includes("resources/rooms") || pathname.includes("resources/inventory");
  };

  const isTeamDropdownOptionActive = () => {
    return pathname.includes("staff/roles") || pathname.includes("staff/users");
  };

  const isBookingsDropdownOptionActive = () => {
    return pathname.includes("front-office/reviews") || pathname.includes("front-office/feedbacks") || pathname.includes("front-office/visitors") || pathname.includes("front-office/quotations") || pathname.includes("front-office/reservations") || pathname.includes("front-office/guests");
  };

  const isOffersDropdownOptionActive = () => {
    return pathname.includes("offers/coupons");
  };

  const isExpensesDropdownOptionActive = () => {
    return pathname.includes("expenses/all-expenses");
  };
  const isSupportDropdownOptionActive = () => {
    return pathname.includes("support/guest-support") || pathname.includes("support/staystation-support");
  };
  const isSettingsDropdownOptionActive = () => {
    return pathname.includes("settings/profile") || pathname.includes("settings/subscription");
  };

  useEffect(() => {
    setActiveDrop(pathname);
  }, [pathname]);

  const [mastersDropdownOpen, setMastersDropdownOpen] = useState(false);  // Masters
  const [mastersDropdownOpenlocation, setMastersDropdownOpenlocation] = useState(false); // Resources
  const [mastersDropdownOpen1, setMastersDropdownOpen1] = useState(false);  // Team
  const [mastersDropdownOpeninvoice, setMastersDropdownOpeninvoice] = useState(false);
  const [mastersDropdownOpenexpense, setMastersDropdownOpenexpense] = useState(false);
  const [mastersDropdownOpenvisitor, setMastersDropdownOpenvisitor] = useState(false);
  const [mastersDropdownOpenOffer, setMastersDropdownOpenOffer] = useState(false);
  const [mastersDropdownOpenenqurie, setMastersDropdownOpenenqurie] = useState(false);
  const [mastersDropdownOpenoccupancy, setMastersDropdownOpenoccupancy] = useState(false);
  const [mastersDropdownOpensetting, setMastersDropdownOpensetting] = useState(false);
  const [mastersDropdownOpenbooking, setMastersDropdownOpenbooking] = useState(false);
  const [mastersDropdownOpensupport, setMastersDropdownOpensupport] = useState(false);
  const [mastersDropdownOpenclient, setMastersDropdownOpenclient] = useState(false);
  const [mastersDropdownOpencommunity, setMastersDropdownOpencommunity] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsSidebarOpen]);
  const toggleDropdown = () => {
    setMastersDropdownOpen(!mastersDropdownOpen);
  };
  const toggleDropdowninvoice = () => {
    setMastersDropdownOpeninvoice(!mastersDropdownOpeninvoice);
  };
  const toggleDropdownexpense = () => {
    setMastersDropdownOpenexpense(!mastersDropdownOpenexpense);
  };
  const toggleDropdownvisitor = () => {
    setMastersDropdownOpenvisitor(!mastersDropdownOpenvisitor);
  };
  const toggleDropdownOffer = () => {
    setMastersDropdownOpenOffer(!mastersDropdownOpenOffer);
  };
  const toggleDropdownenqurie = () => {
    setMastersDropdownOpenenqurie(!mastersDropdownOpenenqurie);
  };
  const toggleDropdownlocation = () => {
    setMastersDropdownOpenlocation(!mastersDropdownOpenlocation);
  };
  const toggleDropdown1 = () => {
    setMastersDropdownOpen1(!mastersDropdownOpen1);
  };
  const toggleDropdownoccupancy = () => {
    setMastersDropdownOpenoccupancy(!mastersDropdownOpenoccupancy);
  };
  const toggleDropdownsetting = () => {
    setMastersDropdownOpensetting(!mastersDropdownOpensetting);
  };
  const toggleDropdownbooking = () => {
    setMastersDropdownOpenbooking(!mastersDropdownOpenbooking);
  };
  const toggleDropdownsupport = () => {
    setMastersDropdownOpensupport(!mastersDropdownOpensupport);
  };
  const toggleDropdownclient = () => {
    setMastersDropdownOpenclient(!mastersDropdownOpenclient);
  };
  const toggleDropdowncommunity = () => {
    setMastersDropdownOpencommunity(!mastersDropdownOpencommunity);
  };

  useEffect(() => {
    // Reset all dropdowns to false initially
    setMastersDropdownOpen(false);
    setMastersDropdownOpeninvoice(false);
    setMastersDropdownOpenexpense(false);
    setMastersDropdownOpenvisitor(false);
    setMastersDropdownOpenOffer(false);
    setMastersDropdownOpenenqurie(false);
    setMastersDropdownOpenoccupancy(false);
    setMastersDropdownOpenlocation(false);
    setMastersDropdownOpen1(false);
    setMastersDropdownOpensetting(false);
    setMastersDropdownOpenbooking(false);
    setMastersDropdownOpensupport(false);
    setMastersDropdownOpenclient(false);
    setMastersDropdownOpencommunity(false);

    if (pathname.includes("masters/property-types") || pathname.includes("masters/room-types") || pathname.includes("masters/pricing-types") || pathname.includes("masters/property-amenities") || 
        pathname.includes("masters/room-amenities") || pathname.includes("masters/faqs") || pathname.includes("masters/policies")) {
      setMastersDropdownOpen(true);
    } else if (pathname.includes("resources/properties") || pathname.includes("resources/inventory") || pathname.includes("resources/rooms")) {
      setMastersDropdownOpenlocation(true);
    } else if (pathname.includes("staff/roles") || pathname.includes("staff/users")) {
      setMastersDropdownOpen1(true);
    } else if (pathname.includes("masters/invoice")) {
      setMastersDropdownOpeninvoice(true);
    } else if (pathname.includes("expenses/all-expenses")) {
      setMastersDropdownOpenexpense(true);
    } else if (pathname.includes("visitors/all-visitors")) {
      setMastersDropdownOpenvisitor(true);
    } else if (pathname.includes("offers/coupons")) {
      setMastersDropdownOpenOffer(true);
    } else if (pathname.includes("enquiries/all-enquiries")) {
      setMastersDropdownOpenenqurie(true);
    } else if (pathname.includes("settings/profile") || pathname.includes("settings/subscription")) {
      setMastersDropdownOpensetting(true);
    } else if (pathname.includes("front-office/reviews") || pathname.includes("front-office/feedbacks") || pathname.includes("front-office/visitors")
      || pathname.includes("front-office/quotations") || pathname.includes("front-office/reservations") || pathname.includes("front-office/guests") ) {
      setMastersDropdownOpenbooking(true);
    } else if (pathname.includes("support/guest-support") || pathname.includes("support/staystation-support")) {
      setMastersDropdownOpensupport(true);
    } else if (pathname.includes("client/all-clients")) {
      setMastersDropdownOpenclient(true);
    } else if (pathname.includes("community")) {
      setMastersDropdownOpencommunity(true);
    }
  }, [pathname]);

  const handleLogout = async () => {
    if (role === "team") {
      localStorage.clear();
      try {
        let response = await fetch(url + clientpoints.team_logout, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        navigate(`/hotel/${hotelId}/admin/login`);
      } catch (error) {
        console.error("Error:", error);
        alert("An error occurred during logout.");
      }
    } else if (role === "client") {
      localStorage.clear();
      try {
        let response = await fetch(url + clientpoints.logout, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        navigate("/login");
      } catch (error) {
        console.error("Error:", error);
        alert("An error occurred during logout.");
      }
    }
  };

  return (
    <div>
      <div id="mySidenav" className={`sidenav ${isSidebarOpen ? "open" : ""}`} ref={sidebarRef}>

        <a href="javascript:void(0)" className="closebtn" onClick={() => setIsSidebarOpen(false)}>&times;</a>
        <div className="desk-flx"><img src="/img/stay_white.png" className="img-fluid logo" alt="staystation Logo" /></div>

        <div className="top-marginn">
          
          {/* Dashboard Start*/}
          <Link to="dashboard" className={pathname.includes("dashboard") ? `pages active` : "pages"}>
            <i className="mstr ri-home-4-line"></i>Dashboard
          </Link>
          {/* Dashboard End */}


          {/* Master Start */}
          <div className="dropdown">
            <Link className={`pages ${isMastersDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdown}>
              <i className="mstr ri-archive-stack-line mstr"></i>Masters <i className={mastersDropdownOpen ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpen ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.property_type?.view && (
                    <Link to="masters/property-types" className={[pathname.includes("masters/property-types") ? `pages1 active` : "pages1"]}>
                      <i class="mstr ri-hotel-line fillicn"></i>Property Types
                      {/* <i className="mstr ri-circle-fill fillicn"></i>Property Types */}
                    </Link>
                  )}
                  {roleObject?.room_type?.view && (
                    <Link to="masters/room-types" className={pathname.includes("masters/room-types") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-hotel-bed-line fillicn"></i>Room Types
                    </Link>
                  )}
                  {roleObject?.pricing_type?.view && (
                    <Link to="masters/pricing-types" className={pathname.includes("masters/pricing-types") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-calculator-line fillicn"></i>Pricing Types
                    </Link>
                  )}{" "}
                  {roleObject?.property_amenity?.view && (
                    <Link to="masters/property-amenities" className={pathname.includes("masters/property-amenities") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-service-bell-line fillicn"></i>Property Amenities
                    </Link>
                  )}{" "}
                  {roleObject?.apartment_amenity?.view && (
                    <Link to="masters/room-amenities" className={pathname.includes("masters/room-amenities") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-service-bell-line fillicn"></i>Room Amenities
                    </Link>
                  )}{" "}
                  {roleObject?.faq?.view && (
                    <Link to="masters/faqs" className={pathname.includes("masters/faqs") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-file-list-3-line fillicn"></i>FAQs
                    </Link>
                  )}{" "}
                  {roleObject?.faq?.view && (
                    <Link to="masters/policies" className={pathname.includes("masters/policies") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-article-line fillicn"></i>Policies
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link to="masters/property-types" className={[pathname.includes("masters/property-types") ? `pages1 active` : "pages1"]}>
                    <i className="mstr ri-hotel-line fillicn_14px"></i>Property Types
                  </Link>
                  <Link to="masters/room-types" className={pathname.includes("masters/room-types") ? `pages1 active` : "pages1"}>
                    {/* <i className="mstr ri-circle-fill fillicn"></i>Room Types */}
                    <i className="mstr ri-hotel-bed-line fillicn_14px"></i>Room Types
                  </Link>
                  <Link to="masters/pricing-types" className={pathname.includes("masters/pricing-types") ? `pages1 active` : "pages1"}>
                    {/* <i className="mstr ri-circle-fill fillicn"></i>Pricing Types */}
                    <i className="mstr ri-calculator-line fillicn_14px"></i>Pricing Types
                  </Link>
                  <Link to="masters/property-amenities" className={pathname.includes("masters/property-amenities") ? `pages1 active` : "pages1"}>
                    {/* <i className="mstr ri-circle-fill fillicn"></i>Property Amenities */}
                    <i className="mstr ri-service-bell-line fillicn_14px"></i>Property Amenities
                  </Link>
                  <Link to="masters/room-amenities" className={pathname.includes("masters/room-amenities") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-service-bell-line fillicn_14px"></i>Room Amenities
                  </Link>
                  <Link to="masters/faqs" className={pathname.includes("masters/faqs") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-file-list-3-line fillicn_14px"></i>FAQs
                  </Link>
                  <Link to="masters/policies" className={pathname.includes("masters/policies") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-article-line fillicn_14px"></i>Policies
                  </Link>
                </>
              )}
            </div>
          </div>
          {/* Master End */}


          {/* Resource Start */}
          <div className="dropdown">
            <Link className={`pages ${isResourcesDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdownlocation}>
              <i className="mstr ri-map-pin-line"></i>Resources <i className={mastersDropdownOpenlocation ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpenlocation ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {/* {roleObject?.area?.view && (
                    <Link to="resources/areas" className={pathname.includes("resources/areas") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-circle-fill fillicn"></i>Areas
                    </Link>
                  )} */}
                  {roleObject?.property?.view && (
                    <Link to="resources/properties" className={pathname.includes("resources/properties") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-hotel-line fillicn"></i>Properties
                    </Link>
                  )}
                  {roleObject?.room?.view && (
                    <Link to="resources/rooms" className={pathname.includes("resources/rooms") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-hotel-bed-line fillicn"></i>Rooms
                    </Link>
                  )}
                  {roleObject?.room_inventory_price?.view && (
                    <Link to="resources/inventory" className={pathname.includes("resources/inventory") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-calendar-line fillicn"></i>Inventory
                    </Link>
                  )}
                </>
              ) : (
                <>
                  {/* <Link to="resources/areas" className={pathname.includes("resources/areas") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-circle-fill fillicn"></i>Areas
                  </Link> */}
                  <Link to="resources/properties" className={pathname.includes("resources/properties") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-hotel-line fillicn_14px"></i>Properties
                  </Link>
                  <Link to="resources/rooms" className={pathname.includes("resources/rooms") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-hotel-bed-line fillicn_14px"></i>Rooms
                  </Link>
                  <Link to="resources/inventory" className={pathname.includes("resources/inventory") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-calendar-line fillicn_14px"></i>Inventory
                  </Link>
                </>
              )}
            </div>
          </div>
          {/* Resource End */}


          {/* Front-Office Start */}
          <div className="dropdown">
            <Link className={`pages ${isBookingsDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdownbooking}>
              <i className="mstr ri-calendar-event-line"></i>Front Office <i className={mastersDropdownOpenbooking ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpenbooking ? "open" : ""}`}>
              {/* Dropdown options go here */}

              {role === "team" ? (
                <>
                  {roleObject?.booking?.view && (
                    <Link className={pathname.includes("front-office/reservations") ? `pages1 active` : "pages1"} to="front-office/reservations">
                      <i className="mstr ri-reserved-line fillicn"></i>Reservations
                    </Link>
                  )}
                  {roleObject?.enquiry?.view && (
                    <Link className={pathname.includes("front-office/quotations") ? `pages1 active` : "pages1"} to="front-office/quotations">
                      <i className="mstr ri-bill-line fillicn"></i>Quotations
                    </Link>
                  )}
                  {roleObject?.user?.view && (
                    <Link to="front-office/guests" className={pathname.includes("front-office/guests") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-group-line fillicn"></i>Guests
                    </Link>
                  )}
                  {roleObject?.visitor?.view && (
                    <Link className={pathname.includes("front-office/visitors") ? `pages1 active` : "pages1"} to="front-office/visitors">
                      <i className="mstr ri-user-line fillicn"></i>Visitors
                    </Link>
                  )}
                  {roleObject?.feedback?.view && (
                    <Link className={pathname.includes("front-office/feedbacks") ? `pages1 active` : "pages1"} to="front-office/feedbacks">
                      <i className="mstr ri-feedback-line fillicn"></i>Feedbacks
                    </Link>
                  )}
                  {roleObject?.review?.view && (
                    <Link className={pathname.includes("front-office/reviews") ? `pages1 active` : "pages1"} to="front-office/reviews">
                      <i className="mstr ri-star-line fillicn"></i>Reviews
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link className={pathname.includes("front-office/reservations") ? `pages1 active` : "pages1"} to="front-office/reservations">
                    <i className="mstr ri-reserved-line fillicn_14px"></i>Reservations
                  </Link>
                  <Link className={pathname.includes("front-office/quotations") ? `pages1 active` : "pages1"} to="front-office/quotations">
                    <i className="mstr ri-bill-line fillicn_14px"></i>Quotations
                  </Link>
                  <Link className={pathname.includes("front-office/guests") ? `pages1 active` : "pages1"} to="front-office/guests">
                    <i className="mstr ri-group-line fillicn_14px"></i>Guests
                  </Link>
                  <Link className={pathname.includes("front-office/visitors") ? `pages1 active` : "pages1"} to="front-office/visitors">
                    <i className="mstr ri-user-line fillicn_14px"></i>Visitors
                  </Link>
                  <Link className={pathname.includes("front-office/feedbacks") ? `pages1 active` : "pages1"} to="front-office/feedbacks">
                    <i className="mstr ri-feedback-line fillicn_14px"></i>Feedbacks
                  </Link>
                  <Link className={pathname.includes("front-office/reviews") ? `pages1 active` : "pages1"} to="front-office/reviews">
                    <i className="mstr ri-star-line fillicn_14px"></i>Reviews
                  </Link>
                </>
              )}
            </div>
          </div>
          {/* Front-Office Start */}


          {/* Offers Start */}
          <div className="dropdown">
            <Link className={`pages ${isOffersDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdownOffer}>
              <i className="mstr ri-user-follow-fill mstr"></i>Offers <i className={mastersDropdownOpenOffer ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpenOffer ? "open" : ""}`}>
              {/* Dropdown options go here */}

              {role === "team" ? (
                <>
                  {roleObject?.coupon?.view && (
                    <Link className={pathname.includes("offers/coupons") ? `pages1 active` : "pages1"} to="offers/coupons">
                      <i className="mstr ri-coupon-line fillicn"></i>Coupons
                    </Link>
                  )}
                </>
              ) : (
                <Link className={pathname.includes("offers/coupons") ? `pages1 active` : "pages1"} to="offers/coupons">
                  <i className="mstr ri-coupon-line fillicn_14px"></i>Coupons
                </Link>
              )}
            </div>
          </div>
          {/* Offers End */}


          {/* Staff Start */}
          <div className="dropdown">
            <Link className={`pages ${isTeamDropdownOptionActive() ? "active" : ""}`} onClick={toggleDropdown1}>
              <i className="mstr ri-team-line"></i>Staff <i className={mastersDropdownOpen1 ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpen1 ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.role?.view && (
                    <Link id="role_view" to="staff/roles" className={pathname.includes("staff/roles") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-file-lock-line fillicn"></i>Roles
                    </Link>
                  )}
                  {roleObject?.team?.view && (
                    <Link id="team_view" to="staff/users" className={pathname.includes("staff/users") ? `pages1 active` : "pages1"}>
                      <i className="mstr ri-team-fill fillicn"></i>Users
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link id="role_view" to="staff/roles" className={pathname.includes("staff/roles") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-file-lock-line fillicn_14px"></i>Roles
                  </Link>
                  <Link id="team_view" to="staff/users" className={pathname.includes("staff/users") ? `pages1 active` : "pages1"}>
                    <i className="mstr ri-team-fill fillicn_14px"></i>Users
                  </Link>
                </>
              )}
              {/* <Link to='team/attendance' className='pages1'><i className='mstr ri-circle-fill fillicn'></i>Attendance</Link> */}
            </div>
          </div>
          {/* Staff End */}


          {/* <div className='dropdown'>
            <Link className={`pages ${mastersDropdownOpeninvoice ? "open" : ""}`} onClick={toggleDropdowninvoice}>
              <i className='mstr ri-file-list-3-line mstr'></i>Invoices <i className={mastersDropdownOpeninvoice ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpeninvoice ? "open" : ""}`}>
              <Link className='pages1'>
                <i className='mstr ri-circle-fill fillicn'></i>All Invoices
              </Link>
              <Link className='pages1'>
                <i className='mstr ri-circle-fill fillicn'></i>Pending Invoices
              </Link>
              <Link className='pages1'>
                <i className='mstr ri-circle-fill fillicn'></i>Paid Invoices
              </Link>
            </div>
          </div> */}

          {/* <div className='dropdown'>
            <Link className={`pages ${mastersDropdownOpenoccupancy ? "open" : ""}`} onClick={toggleDropdownoccupancy}>
              <i className='mstr ri-bill-line'></i>Occupancy <i className={mastersDropdownOpenoccupancy ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpenoccupancy ? "open" : ""}`}>
              <Link to='occupancy/reports' className='pages1'>
                <i className='mstr ri-circle-fill fillicn'></i>Overview
              </Link>
              
              <Link className='pages1'>
                <i className='mstr ri-circle-fill fillicn'></i>Analytics{" "}
              </Link>
             
            </div>
          </div> */}

          {/* <Link href="#" className="pages"><i className="mstr ri-group-2-line"></i> Community</Link> */}
          {/* <div className='dropdown'>
            <Link className={`pages ${mastersDropdownOpencommunity ? "open" : ""}`} onClick={toggleDropdowncommunity}>
              <i className='mstr ri-group-2-line'></i>Community <i className={mastersDropdownOpencommunity ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpencommunity ? "open" : ""}`}>
              <Link to='community/posts' className='pages1'>
                <i className='mstr ri-circle-fill fillicn'></i>Posts
              </Link>
              <Link to='community/events' className='pages1'>
                <i className='mstr ri-circle-fill fillicn'></i>Events
              </Link>
              <Link to='community/offers' className='pages1'>
                <i className='mstr ri-circle-fill fillicn '></i>Offers
              </Link>
              <Link to='community/bulk-email' className='pages1'>
                <i className='mstr ri-circle-fill fillicn '></i>Bulk Email
              </Link>
            </div>
          </div> */}

          {/* <Link href="#" className="pages"><i className="mstr ri-money-rupee-circle-line"></i>Expenses</Link> */}
          {/* 
          <div className="dropdown">
            <Link className={`pages ${isExpensesDropdownOptionActive() ? "open" : ""}`} onClick={toggleDropdownexpense}>
              <i className="mstr ri-money-rupee-circle-line mstr"></i>Expenses <i className={mastersDropdownOpenexpense ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpenexpense ? "open" : ""}`}>
              {role === "team" ? (
                <>
                  {roleObject?.expense?.view && (
                    <Link className={pathname.includes("expenses/all-expenses") ? `pages1 active` : "pages1"} to="expenses/all-expenses">
                      <i className="mstr ri-circle-fill fillicn"></i>All Expenses
                    </Link>
                  )}
                </>
              ) : (
                <Link className={pathname.includes("expenses/all-expenses") ? `pages1 active` : "pages1"} to="expenses/all-expenses">
                  <i className="mstr ri-circle-fill fillicn"></i>All Expenses
                </Link>
              )}
            </div>
          </div>
          */}
          
          <div className="dropdown">
            <Link className={`pages ${isSupportDropdownOptionActive() ? "open" : ""}`} onClick={toggleDropdownsupport}>
              <i className="mstr ri-customer-service-2-line"></i>Support <i className={mastersDropdownOpensupport ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpensupport ? "open" : ""}`}>
              {/* Dropdown options go here */}

              {role === "team" ? (
                <>
                  {roleObject?.ticket?.view && (
                    <Link className={pathname.includes("support/guest-support") ? `pages1 active` : "pages1"} to="support/guest-support">
                      <i className="mstr ri-customer-service-2-line fillicn"></i>Guest Support{" "}
                    </Link>
                  )}
                  {roleObject?.client_ticket?.view && (
                    <Link className={pathname.includes("support/staystation-support") ? `pages1 active` : "pages1"} to="support/staystation-support">
                      <i className="mstr ri-customer-service-line fillicn"></i>StayStation Support{" "}
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link className={pathname.includes("support/guest-support") ? `pages1 active` : "pages1"} to="support/guest-support">
                    <i className="mstr ri-customer-service-2-line fillicn_14px"></i>Guest Support{" "}
                  </Link>
                  <Link className={pathname.includes("support/staystation-support") ? `pages1 active` : "pages1"} to="support/staystation-support">
                    <i className="mstr ri-customer-service-line fillicn_14px"></i>StayStation Support{" "}
                  </Link>
                </>
              )}
            </div>
          </div>

          {/* <Link href="#" className="pages"><i className="mstr ri-settings-3-line"></i></Link> */}

          <div className="dropdown">
            <Link className={`pages ${isSettingsDropdownOptionActive() ? "open" : ""}`} onClick={toggleDropdownsetting}>
              <i className="mstr ri-settings-3-line"></i>Settings <i className={mastersDropdownOpensetting ? " ri-arrow-up-s-line rgt" : " ri-arrow-down-s-line rgt"}></i>
            </Link>
            <div className={`dropdown-options ${mastersDropdownOpensetting ? "open" : ""}`}>
              {/* Dropdown options go here */}

              {role === "team" ? (
                <>
                  {roleObject?.hotel?.view && (
                    <Link className={pathname.includes("settings/profile") ? `pages1 active` : "pages1"} to="settings/profile">
                      <i className="mstr ri-profile-line fillicn"></i>Profile
                    </Link>
                  )}
                  {roleObject?.subscription?.view && (
                    <Link className={pathname.includes("settings/subscription") ? `pages1 active` : "pages1"} to="settings/subscription">
                      <i className="mstr ri-remote-control-line fillicn"></i>
                      Subscription{" "}
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link className={pathname.includes("settings/profile") ? `pages1 active` : "pages1"} to="settings/profile">
                    <i className="mstr ri-profile-line fillicn_14px"></i>Profile
                  </Link>
                  <Link className={pathname.includes("settings/subscription") ? `pages1 active` : "pages1"} to="settings/subscription">
                    <i className="mstr ri-remote-control-line fillicn_14px"></i>Subscription{" "}
                  </Link>
                </>
              )}

              {/* <Link className='pages1' to='settings/activity-logs'><i className='mstr ri-circle-fill fillicn '></i>Activity Logs</Link> */}
            </div>
          </div>
          <Link className="pages" onClick={handleLogout}>
            <i className="mstr ri-shut-down-line"></i>Logout
          </Link>
          {/* <Link href="#" className="pages"><i className="mstr ri-customer-service-2-line"></i>Support</Link> */}
        </div>
      </div>

      <div className="logo-flx">
        <div className="logo-flx1">
          <img src="/img/staystation.png" className="img-fluid logo2" alt="staystation Logo" />
        </div>
        <div className="logo-flx2">
          <span style={{ fontSize: "30px", cursor: "pointer" }} onClick={toggleSidebar}>
            &#9776;
          </span>
        </div>
      </div>
    </div>
  );
}

export default Component;
