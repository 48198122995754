import React from "react";
// import "../../../../../nav/components/location/component.css";
import "./cards.css";

// Card for Cabins, Seats, Meeting Rooms
function Card1({ data, setSelectedItem, setIsEditModal, setIsViewModal, handleDelete, typeTeamRoleObject, whoAmI, tableName, iconName }) {
  let icon;
  switch (iconName) {
    case 'Property Types':
      icon = <i className='ri-hotel-line keybord-icn'></i>;
      break;
    case 'Room Types':
      icon = <i className='ri-hotel-bed-line keybord-icn'></i>;
      break;
    case 'Pricing Types':
      icon = <i className='ri-calculator-line keybord-icn'></i>;
      break;
    case 'Property Amenities':
      icon = <i className='ri-service-bell-line keybord-icn'></i>;
      break;
    case 'Room Amenities':
      icon = <i className='ri-service-bell-line keybord-icn'></i>;
      break;
    case 'FAQs':
      icon = <i className='ri-file-list-3-line keybord-icn'></i>;
      break;
    case 'Policies':
      icon = <i className='ri-article-line keybord-icn'></i>;
      break;
    case 'Properties':
      icon = <i className='ri-hotel-line keybord-icn'></i>;
      break;
    case 'Roles':
      icon = <i className='ri-file-lock-line keybord-icn'></i>;
      break;
    case 'Staffs':
      icon = <i className='ri-team-fill keybord-icn'></i>;
      break;
    default:
      icon = <i class='ri-keyboard-box-line keybord-icn'></i>;
  }
  return (
    <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12 roleClass'>
      <div className='cabins-card'>
        <div class='cabin-sideline'></div>
        <div className='row'>
          <div className='col-12'>
            <div className='cabin-sheet'>
              <div className='cabin-number'>
                {icon}
              </div>
              <div className='cabin-text'>
                <p className='cabin-totalseat'>{data?.name}</p>
              </div>
            </div>
            <div className='botflx-cabin'>
              <div className='cabin-count'>
                {data?.number_of_person} Role
                {data?.number_of_person == 1 ? "" : ""}
              </div>
              <div className='cabin-editicn'>
                {whoAmI === "team" ? (
                  <>
                    {typeTeamRoleObject?.[tableName]?.view && (
                      <i
                        class='ri-eye-line floor-view-icn'
                        onClick={() => {
                          setSelectedItem(data);
                          setIsViewModal(true);
                        }}
                      ></i>
                    )}
                    {typeTeamRoleObject?.[tableName]?.edit && (
                      <i
                        class='ri-pencil-line floor-pencil-icn'
                        onClick={() => {
                          setSelectedItem(data);
                          setIsEditModal(true);
                        }}
                      ></i>
                    )}
                    {typeTeamRoleObject?.[tableName]?.delete && (
                      <i
                        class='ri-delete-bin-6-line floor-trash-icn'
                        onClick={() => {
                          handleDelete(data._id, data?.name);
                        }}
                      ></i>
                    )}
                  </>
                ) : (
                  <>
                    <i
                      class='ri-eye-line floor-view-icn' style={{marginRight:"10px"}}
                      onClick={() => {
                        setSelectedItem(data);
                        setIsViewModal(true);
                      }}
                    ></i>
                    <i
                      class='ri-pencil-line floor-pencil-icn'
                      onClick={() => {
                        setSelectedItem(data);
                        setIsEditModal(true);
                      }}
                    ></i>
                    <i
                      class='ri-delete-bin-6-line floor-trash-icn'
                      onClick={() => {
                        handleDelete(data._id, data?.name);
                      }}
                    ></i>
                  </>
                )}
              </div>
              {/* <button className="view-btn-2">Manage Floors</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card1;
