import { useParams } from "react-router-dom";
import "./../css/style.css";
import { AuthUserContext } from "../../lib/AuthUserContext.js";
import { useContext } from "react";

const Component = () => {
  const { hotelId } = useParams();
  let hotel_url;

  if (hotelId) {
    console.log('parama');
    hotel_url = hotelId;
  } else {
    console.log('context');
    const { hotelUrl } = useContext(AuthUserContext);
    hotel_url = hotelUrl;
  }

  return <>Welcome {hotel_url}</>;
};

export default Component;
