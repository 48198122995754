import React, { useState, useEffect, useContext } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { toast, ToastContainer } from "react-toastify";
import InventoryModal from "../../components/Resources/inventory/add_edit_modal/Component";
import { format } from "date-fns";
import Select from "react-select";
import { useParams, useNavigate } from "react-router-dom";

import { AuthUserContext } from "../../../../lib/AuthUserContext";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../lib/lib";

export default function Inventory() {
  const { hotelId } = useParams();

  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const [properties, setProperties] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [formData, setFormData] = useState({ property: "", room: "" });
  const [selectValues, setSelectValues] = useState({
    property: "",
    room: "",
    startDate: "",
    endDate: "",
  });
  const [isInventoryModal, setIsInventoryModal] = useState(false);
  const [events, setEvents] = useState([]);

  const [filteredDates, setFilteredDates] = useState([]);

  const [defaultRoomCount, setDefaultRoomCount] = useState();
  const [defaultBasePrice, setDefaultBasePrice] = useState();

  const handleSelect = (e) => {
    const endDate = new Date(e.end);
    const previousDate = new Date(endDate);
    previousDate.setDate(endDate.getDate() - 1).toString();
    if (new Date(e.start.setHours(0, 0, 0, 0)) < new Date().setHours(0, 0, 0, 0)) return toast.error("Cannot select previous days");
    if (!formData.property && !formData.room) return toast.error("Select property and room");
    if (!formData.property) return toast.error("Select property");
    if (!formData.room) return toast.error("Select room");
    setSelectValues((prev) => ({ ...prev, startDate: e.start, endDate: previousDate }));
    setIsInventoryModal(true);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <p className="mx-auto lg:text-[20px] md:text-[16px] sm:text-[14px] text-[12px] text-green-500 font-[500] bg-transparent">{eventInfo.event.title}</p>
      </>
    );
  };

  const handleSavePriceUpdates = async (item) => {
    const date_to = format(item.date_to, "yyyy-MM-dd");
    const date_from = format(item.date_from, "yyyy-MM-dd");

    const dataToSave = {
      ...item,
      date_from: date_from,
      date_to: date_to,
      property_id: item.property,
      room_id: item.room,
    };

    console.log("data to save", dataToSave);

    try {
      const response = await fetch(url + clientpoints.room_inventory_price_edit, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({
          urlName: hotelId,
          filters: {},
          data: {
            ...dataToSave,
          },
        }),
      });

      if (!response.ok) {
        // navigate(client_prefix);
      }

      const responseData = await response.json();

      if (responseData.Status) {
        fetchData();
        toast.success(responseData.Message);
      } else {
        toast.error(responseData.Message);
        throw new Error(responseData.Message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await fetch(url + clientpoints.property_view, {
          method: "POST",
          headers: pageHeaders,
          body: JSON.stringify({
            urlName: hotelId,
            filters: {},
          }),
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch client data");
        }
        const data = await response.json();
        setProperties(data.Data);
      } catch (error) {
        toast.error(error);
      }
    };

    fetchProperties();
  }, []);

  useEffect(() => {
    const property = properties.find((item) => item._id === formData.property) || "";

    const fetchRooms = async () => {
      try {
        const response = await fetch(url + clientpoints.room_view, {
          method: "POST",
          headers: pageHeaders,
          body: JSON.stringify({
            urlName: hotelId,
            filters: { property_id: property._id },
          }),
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch client data");
        }
        const data = await response.json();
        setRooms(data.Data);
      } catch (error) {
        toast.error(error);
      }
    };

    fetchRooms();

    setSelectValues((prev) => ({
      ...prev,
      property: property ? { label: property.title, value: property._id } : "",
    }));
  }, [formData.property, properties]);

  useEffect(() => {
    const room = rooms.find((item) => item._id === formData.room) || "";
    setSelectValues((prev) => ({
      ...prev,
      room: room ? { label: room.name, value: room._id } : "",
    }));
  }, [formData.room]);

  const fetchData = async () => {
    try {
      const response = await fetch(url + clientpoints.room_inventory_price_view, {
        method: "POST",
        headers: pageHeaders,
        body: JSON.stringify({
          urlName: hotelId,
          filters: { property_id: formData.property, room_id: formData.room },
        }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch inventory data");
      }
      const data = await response.json();

      setFilteredDates(data.Data);
      setDefaultBasePrice(data.defaultBasePrice);

      // setRooms(data.Data);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (formData.property && formData.room) fetchData();
  }, [formData.property, formData.room]);

  useEffect(() => {
    if (formData.property && formData.room) {
      const today = new Date();
      const twelveMonthsBefore = new Date(today);
      twelveMonthsBefore.setFullYear(today.getFullYear() - 1);
      const twelveMonthsAfter = new Date(today);
      twelveMonthsAfter.setFullYear(today.getFullYear() + 1);

      if (filteredDates.length > 0) {
        // Sort the events by start date
        const sortedEvents = filteredDates
          .map((item) => {
            const startDate = item.date_from.split("T")[0];
            const endDate = new Date(item.date_to);
            endDate.setDate(endDate.getDate() + 1); // Include the end date
            return {
              start: startDate,
              end: endDate.toISOString().split("T")[0],
              title: item.base_price,
            };
          })
          .sort((a, b) => new Date(a.start) - new Date(b.start));

        const filledEvents = [];
        let lastEndDate = null;

        sortedEvents.forEach((event) => {
          if (lastEndDate && new Date(lastEndDate) < new Date(event.start)) {
            // Fill the gap with a new event starting from the last event's end date
            filledEvents.push({
              start: lastEndDate,
              end: event.start,
              title: defaultBasePrice,
            });
          }

          filledEvents.push(event);
          lastEndDate = event.end; // Update last end date to the current event's end date
        });

        // events for 12 months before the first event
        if (new Date(filledEvents[0].start) > twelveMonthsBefore) {
          filledEvents.unshift({
            start: twelveMonthsBefore.toISOString().split("T")[0],
            end: filledEvents[0].start,
            title: defaultBasePrice,
          });
        }

        // events for 12 months after the last event
        if (new Date(filledEvents[filledEvents.length - 1].end) < twelveMonthsAfter) {
          filledEvents.push({
            start: filledEvents[filledEvents.length - 1].end,
            end: twelveMonthsAfter.toISOString().split("T")[0],
            title: defaultBasePrice,
          });
        }

        setEvents(filledEvents);
      } else {
        // If no data in inventory, create single 12 month before & after event from current date with default price
        const singleEvent = {
          start: twelveMonthsBefore.toISOString().split("T")[0],
          end: twelveMonthsAfter.toISOString().split("T")[0],
          title: defaultBasePrice,
        };
        setEvents([singleEvent]);
      }
    }
  }, [filteredDates, defaultBasePrice, formData.property, formData.room]);

  function findEditItem() {
    const selectedDate = new Date(selectValues.startDate);
    selectedDate.setHours(0, 0, 0, 0);

    const editItem = filteredDates?.find((item) => {
      const dateFrom = new Date(item.date_from);
      const dateTo = new Date(item.date_to);
      dateFrom.setHours(0, 0, 0, 0);
      dateTo.setHours(0, 0, 0, 0);

      return selectedDate >= dateFrom && selectedDate <= dateTo;
    });

    return editItem;
  }

  return (
    <>
      <ToastContainer />
      {isInventoryModal && (
        <InventoryModal
          defaultRoomCount={defaultRoomCount}
          defaultBasePrice={defaultBasePrice}
          modalName={"Update Inventory"}
          isModalOpen={isInventoryModal}
          setIsModalOpen={setIsInventoryModal}
          saveFunc={handleSavePriceUpdates}
          selectedStartDate={selectValues.startDate}
          selectedEndDate={selectValues.endDate}
          selectedProperty={selectValues.property.value}
          selectedApartment={selectValues.room.value}
          editItem={findEditItem()}

          // editItem={filteredDates?.find((item) => format(item.date_from, "dd MMM yyyy") == format(selectValues.startDate, "dd MMM yyyy"))}
        />
      )}
      <h2 className="h2 my-5 pt-5 mb-0" style={{ paddingLeft: "40px" }}>
        Inventory
      </h2>

      <div className="shadow p-2 bg-white rounded">
        <div className="row mb-3" style={{ padding: "0px 35px" }}>
          <div className="col-sm-6">
            <ReactSelectInput
              label="Property"
              name="property"
              options={properties?.map((item) => ({
                label: item.title,
                value: item._id,
              }))}
              value={selectValues.property}
              onChange={(_, value) => {
                setFormData((prev) => ({
                  ...prev,
                  property: value,
                  room: "",
                }));
              }}
              customSize={true}
              customStyles={customStyles}
            />
          </div>
          <div className="col-sm-6">
            <ReactSelectInput
              label="Room Type"
              name="room"
              options={rooms.map((item) => ({
                label: item.name,
                value: item._id,
              }))}
              value={selectValues.room}
              onChange={(_, value) => {
                setFormData((prev) => ({
                  ...prev,
                  room: value,
                }));
              }}
              customSize={true}
              customStyles={customStyles}
            />
          </div>
        </div>
        <div style={{ width: "100%", padding:"0px 35px" }}>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            // dateClick={handleDateClicked}
            eventContent={renderEventContent}
            events={events}
            //  eventDidMount={eventDidMount}
            selectable={true} // Enable date selection
            // editable={true} // Enable dragging events and resizing events
            select={handleSelect} // Handle date selection
            // events={filteredDates?.map((item) => ({
            //   title: `₹${item.base_price}`,
            //   date: item.dateFrom,
            // }))}
          />
        </div>
      </div>
    </>
  );
}

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
};

export const ReactSelectInput = ({ label, name, options = [], value = "", disabled = false, onChange, isMulti = false, customStyles = {}, customSize = false }) => {
  return (
    <div>
      <label className="fw-bold">{label}</label>
      <Select
        isMulti={isMulti}
        options={options}
        value={value}
        onChange={(val) =>
          isMulti
            ? onChange(
                name,
                val.map((item) => item.value)
              )
            : onChange(name, val.value)
        }
        placeholder="Select..."
        isDisabled={disabled}
        styles={customSize ? customStyles : {}}
        menuPortalTarget={customSize ? document.body : ""}
      />
    </div>
  );
};
