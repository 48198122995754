import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";

import { toast } from "react-toastify";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const { hotelId } = useParams();
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const initialFormData = {
    name: "",
    email: "",
    phone: "",
    billing_detail: {
      billing_name: "",
      billing_address: "",
      gstin: "",
    },
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleChangeObjectInput = (e, type) => {
    setFormData((prev) => ({
      ...prev,
      [type]: { ...prev[type], [e.target.name]: e.target.value },
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid email address";
      isValid = false;
    }
    if (!formData.phone.trim()) {
      errors.phone = "Phone is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.phone)) {
      errors.phone = "Phone number must be 10 digits";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.user_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            ...formData,
          }),
        });

        setFormData(initialFormData);
        const responseData = await response.json();
        if (responseData.Status) {
          console.log("responseData - ", responseData);
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
        }
        onRefresh();
        setIsModalOpen(false);
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData(initialFormData);
  };

  return (
    <>
      {isModalOpen && (
        <div
          className='modal fade show'
          id='exampleModal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
          style={{ display: "block" }}
        >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {modalName}
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='modal-body'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label htmlFor='name' className='col-form-label'>
                        Name
                      </label>
                      <input type='text' className={`form-control ${errors.name ? "is-invalid" : ""}`} id='name' name='name' value={formData.name} onChange={handleChange} />
                      {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor='email' className='col-form-label'>
                        Email
                      </label>
                      <input type='email' className={`form-control ${errors.email ? "is-invalid" : ""}`} id='email' name='email' value={formData.email} onChange={handleChange} />
                      {errors.email && <div className='invalid-feedback'>{errors.email}</div>}
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor='phone' className='col-form-label'>
                        Phone
                      </label>
                      <input type='text' className={`form-control ${errors.phone ? "is-invalid" : ""}`} id='phone' name='phone' value={formData.phone} onChange={handleChange} />
                      {errors.phone && <div className='invalid-feedback'>{errors.phone}</div>}
                    </div>
                    <div className='col-md-6'></div>
                    <div className='col-md-6'>
                      <label htmlFor='billing_name' className='col-form-label'>
                        Billing Name
                      </label>
                      <input
                        type='text'
                        className={`form-control ${errors.billing_name ? "is-invalid" : ""}`}
                        id='billing_name'
                        name='billing_name'
                        value={formData.billing_detail.billing_name}
                        onChange={(e) => handleChangeObjectInput(e, "billing_detail")}
                      />
                      {errors.billing_name && <div className='invalid-feedback'>{errors.billing_name}</div>}
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor='billing_address' className='col-form-label'>
                        Billing Address
                      </label>
                      <input
                        type='text'
                        className={`form-control ${errors.billing_address ? "is-invalid" : ""}`}
                        id='billing_address'
                        name='billing_address'
                        value={formData.billing_detail.billing_address}
                        onChange={(e) => handleChangeObjectInput(e, "billing_detail")}
                      />
                      {errors.billing_address && <div className='invalid-feedback'>{errors.billing_address}</div>}
                    </div>{" "}
                    <div className='col-md-6'>
                      <label htmlFor='gstin' className='col-form-label'>
                        GSTIN
                      </label>
                      <input
                        type='text'
                        className={`form-control ${errors.gstin ? "is-invalid" : ""}`}
                        id='gstin'
                        name='gstin'
                        value={formData.billing_detail.gstin}
                        onChange={(e) => handleChangeObjectInput(e, "billing_detail")}
                      />
                      {errors.gstin && <div className='invalid-feedback'>{errors.gstin}</div>}
                    </div>{" "}
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
