import AllVisitor from '../../components/visitor/all_visitor/table/Component';
import { toast, ToastContainer } from "react-toastify";

export default function Component() {

    return (
        <>
            <ToastContainer />
            <AllVisitor name="Visitors" />
        </>
    );
}