import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../lib/lib.js";
import { AuthUserContext } from "../../../../lib/AuthUserContext.js";
import { LoadingMutatingDots } from "../../components/ui/Loadings";

import { useParams, useNavigate } from "react-router-dom";
import Card2 from "../../components/Masters/cards/Card2";
import "../../../nav/components/location/component.css";
import { toast, ToastContainer } from "react-toastify";

import AddEditModal from "../../components/Resources/property/add_edit_modal/Component";
import ConfirmModal from "../../components/ui/modals/ConfirmDeleteModal.js";
// import EditModal from "../../components/Resources/property/edit_modal/Component";

export default function Properties() {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { hotelId } = useParams();

  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const [properties, setProperties] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(0);
  const [showFilterMsg, setShowFilterMsg] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 12,
    totalPages: 0,
  });

  const [selectedItem, setSelectedItem] = useState();
  const [isAddModal, setIsAddModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isViewModal, setIsViewModal] = useState(false);

  const [deleteItemId, setDeleteItemId] = useState("");
  const [deleteItemType, setDeleteItemType] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      inputRef.current.focus();
    }
  }, [isLoading]);

  const handleSearchExpand = () => {
    setIsExpanded(true);
  };

  const handleSearchClose = () => {
    setSearchTerm("");
    setIsExpanded(false);
    setShowFilterMsg(false);
    fetchData();
  };

  // to fetch data sorted in desc order by createdAt
  const fetchData = async () => {
    try {
      const sortingObject = { createdAt: -1 };

      const requestBody = {
        page: pagination.pageIndex,
        size: pagination.pageSize,
        urlName: hotelId,
        sorting: sortingObject,
        // search: searchTerm,
      };

      const response = await fetch(url + clientpoints.property_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      console.log("dataaaaa", data);

      setTotal(data.totalCount);
      setPagination((prev) => ({
        ...prev,
        totalPages: Math.ceil(data.totalCount / prev.pageSize),
      }));
      setProperties(data.Data);

      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, hotelId]);

  function handleRefresh() {
    if (isExpanded) {
      setSearchTerm("");
      setIsExpanded(false);
      setShowFilterMsg(false);
    }
    handleHardRefresh();
  }

  // to fetch data again on new entry after setting page index to 1
  const handleHardRefresh = async () => {
    try {
      setIsLoading(true);
      const sortingObject = { createdAt: -1 };

      const requestBody = {
        page: 1,
        size: 12,
        urlName: hotelId,
        sorting: sortingObject,
      };
      const response = await fetch(url + clientpoints.property_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      setTotal(data.totalCount);
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 1,
        pageSize: 12,
        totalPages: Math.ceil(data.totalCount / pagination.pageSize),
      }));
      setProperties(data.Data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error searching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    handleSearch();
  };

  const handleSearch = async () => {
    try {
      const requestBody = {
        search: searchTerm,
        page: 1,
        size: pagination.pageSize,
        urlName: hotelId,
        sorting: { createdAt: -1 },
      };

      setIsLoading(true);
      const response = await fetch(url + clientpoints.property_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setProperties(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      setPagination((prevPagination) => ({
        ...prevPagination,
        pageIndex: 1,
        totalPages: Math.ceil(responseData.totalCount / pagination.pageSize),
      }));
    } catch (error) {
      console.error("Error searching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderPageRange = () => {
    let startIndex;

    startIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;
    if (total === 0) {
      startIndex = 0;
    }

    const endIndex = Math.min(startIndex + pagination.pageSize - 1, total);

    if (startIndex > endIndex) {
      setPagination((prev) => ({
        ...prev,
        pageIndex: prev.pageIndex - 1,
      }));
      return;
    }

    let filterText = "";
    if (showFilterMsg && filter != 0) {
      filterText = ` of total ${total} (filtered ${filter})`;
    } else {
      filterText = ` of ${total}`;
    }
    return `Showing ${startIndex} to ${endIndex}${filterText} entries`;
  };

  const handleDeleteClick = (deleteItemId, type) => {
    setDeleteItemId(deleteItemId);
    setDeleteItemType(type);
    setIsDeleteModal(true);
  };

  const handleDelete = async (deleteItemId, type) => {
    // let deleteConfirm = prompt(`Do you want to delete this record. \nPlease enter "${type}"`);
    // if (deleteConfirm == type) {
    try {
      const response = await fetch(url + clientpoints.property_delete, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({
          urlName: hotelId,
          filters: { _id: deleteItemId },
        }),
      });
      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Failed to delete item");
      }

      if (responseData.Status) {
        if (isExpanded) {
          handleSearchClose();
        } else {
          fetchData();
        }
        toast.success(responseData.Message);
      } else {
        toast.error(responseData.Message);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
    // } else {
    //   toast.error("Not Matched");
    // }
  };

  return (
    <>
      <ToastContainer />
      {isAddModal && <AddEditModal isModalOpen={isAddModal} setIsModalOpen={setIsAddModal} modalName={"Property"} onRefresh={handleRefresh} view='add' />}
      {isEditModal && <AddEditModal isModalOpen={isEditModal} setIsModalOpen={setIsEditModal} modalName={"Property"} onRefresh={handleRefresh} view='edit' data={selectedItem} />}
      {isViewModal && <AddEditModal isModalOpen={isViewModal} setIsModalOpen={setIsViewModal} modalName={"Property"} onRefresh={handleRefresh} view='view' data={selectedItem} />}
      {/* {isEditModal && <EditModal isModalOpen={isEditModal} setIsModalOpen={setIsEditModal} modalName={"Edit Property"} onRefresh={handleRefresh} data={selectedItem} />} */}

      {isDeleteModal && <ConfirmModal isModalOpen={isDeleteModal} setIsModalOpen={setIsDeleteModal} modalName='Confirm Deletion' deleteItemId={deleteItemId} setDeleteItemId={setDeleteItemId} deleteItemType={deleteItemType} setDeleteItemType={setDeleteItemType} handleDelete={handleDelete} />}

      <div className='inner-padding'>
        <div className='featured-flx'>
          <div className='featured-flx1'>
            <p className='main_heading1'>Properties</p>
          </div>

          <div className='featured-flx1'>
            <div className='search-container'>
              <input ref={inputRef} className={`search-bar ${isExpanded ? "expanded" : ""}`} type='text' placeholder='Search Properties' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} onKeyUp={handleSearchSubmit} />
              {!isExpanded && (
                <button className='search-button' onClick={handleSearchExpand}>
                  <i className='ri-search-line'></i>
                </button>
              )}
              {isExpanded && (
                <button className='search-button' onClick={handleSearchClose}>
                  <i className='ri-close-line'></i>
                </button>
              )}
            </div>
            {whoAmI === "team" ? (
              <>
                {typeTeamRoleObject?.property?.add && (
                  <button className='circle-btn-add icon' onClick={() => setIsAddModal(true)}>
                    <i className='ri-add-line'></i>
                  </button>
                )}
              </>
            ) : (
              <>
                <button className='circle-btn-add icon' onClick={() => setIsAddModal(true)}>
                  <i className='ri-add-line'></i>
                </button>
              </>
            )}
          </div>
        </div>
        {isLoading ? (
          <LoadingMutatingDots />
        ) : (
          <>
            <div className='row'>
              {properties?.map((item) => (
                <Card2 key={item._id} type='property' data={item} setSelectedItem={setSelectedItem} setIsEditModal={setIsEditModal} setIsViewModal={setIsViewModal} handleDelete={handleDeleteClick} typeTeamRoleObject={typeTeamRoleObject} whoAmI={whoAmI} tableName='property' iconName='Properties' />
              ))}
            </div>
            <div className='page-flx'>
              <div className='page-flx2'>
                <p className='page-txt1'>{renderPageRange()}</p>
              </div>
              {pagination.totalPages !== 1 && pagination.totalPages !== 0 && (
                <div className='button-container'>
                  {pagination.pageIndex !== 1 && (
                    <button
                      className='button'
                      id='button1'
                      onClick={() =>
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          pageIndex: prevPagination.pageIndex - 1,
                        }))
                      }
                    >
                      <i className='ri-arrow-left-s-line'></i>
                    </button>
                  )}

                  <div className='button active' id='button1'>
                    {pagination.pageIndex}
                  </div>

                  {pagination.pageIndex !== pagination.totalPages && (
                    <button
                      className='button'
                      id='button4'
                      onClick={() =>
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          pageIndex: prevPagination.pageIndex + 1,
                        }))
                      }
                    >
                      <i className='ri-arrow-right-s-line'></i>
                    </button>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
