import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./component.css";

function Component({ isModalOpen, setIsModalOpen, modalName, onRefresh }) {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const { hotelId } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    // image: "",
    isActive: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        const response = await fetch(url + clientpoints.property_amenity_add, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            name: formData.name,
            isActive: formData.isActive,
          }),
        });

        if (!response.ok) {
          navigate(`${client_prefix}`);
        }

        const responseData = await response.json();
        if (responseData.Status) {
          toast.success(responseData.Message);

          setFormData({
            name: "",
            isActive: false,
          });
          setIsModalOpen(false);
        } else {
          toast.error(responseData.Message);
          setIsModalOpen(false);
        }
        onRefresh();
      } catch (error) {
        toast.error(error);
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.warning("Please fill all details!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);

    setFormData({
      name: "",
      isActive: false,
    });
  };
  return (
    <>
      {isModalOpen && (
        <div
          className="modal fade show"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
            {/* <div classNme="modal-dialog modal-dialog-scrollable modal-dialog-centered"> */}
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {modalName}
                </h5>
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="name" className="col-form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Amenities"
                        className={`form-control ${errors.name ? "is-invalid" : ""}`}
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                    </div>
                    {/* <div className='d-flex align-items-center gap-3'>
                     <label>
                        <input type='checkbox' name='isActive' checked={formData.isActive} onChange={(e) => setFormData((prev) => ({ ...prev, isActive: e.target.checked }))} />
                      </label>
                       Active
                    </div> */}
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    {isSubmitting ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;
