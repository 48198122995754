import { useState, useEffect, useRef, useContext } from "react";
import { url, clientpoints, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Table, Form } from "react-bootstrap";
import { FaEdit, FaTrashAlt, FaSyncAlt, FaSort, FaSearch, FaDna, FaFilter, FaSortUp, FaSortDown, FaSortAmountUpAlt, FaSortAmountDownAlt } from "react-icons/fa";

import { toast } from "react-toastify";
import AddModalComponent from "../add_modal/Component.js";
import EditModalComponent from "../edit_modal/Component.js";
import "./component.css";
import "../../../../../nav/components/location/component.css";

import ConfirmModal from "../../../ui/modals/ConfirmDeleteModal.js";

// import AddQuoteModal from "../../../quotation/all_quotation/add_modal/Component.js";

const Component = ({ name = "All" }) => {
  const { hotelId } = useParams();

  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(0);
  const [showFilterMsg, setShowFilterMsg] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
    totalPages: 0,
  });

  const [sorting, setSorting] = useState({ column: null, direction: "desc" });
  const adjustedStartIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;

  const [searchTerm, setSearchTerm] = useState("");

  const [clientName, setClientName] = useState("");
  const [hotelName, setHotelName] = useState("");

  const [isViewModal, setIsViewModal] = useState(false);

  const [deleteItemId, setDeleteItemId] = useState("");
  const [deleteItemType, setDeleteItemType] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const sortingObject = sorting.column ? { [sorting.column]: sorting.direction === "asc" ? 1 : -1 } : { createdAt: "-1" };

      let obj = {
        filters: {
          // ...(hotelId && { hotel_id: hotelId }),
        },
      };

      const requestBody = {
        ...obj,
        urlName: hotelId,
        search: searchTerm,
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
      };

      const response = await fetch(url + clientpoints.enquiry_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setData(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      setPagination((prevPagination) => ({
        ...prevPagination,
        totalPages: Math.ceil(responseData.totalCount / pagination.pageSize),
      }));
    } catch (error) {
      setIsError(true);
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [hotelName, clientName, pagination.pageIndex, pagination.pageSize, sorting.column, sorting.direction]);

  const handleRefresh = () => {
    fetchData();
  };

  const handleHardRefresh = async () => {
    try {
      setIsLoading(true);
      const sortingObject = {};
      setSearchTerm("");
      setHotelName("");
      setClientName("");
      const requestBody = {
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
        filters: {},
        urlName: hotelId,
      };
      const response = await fetch(url + clientpoints.enquiry_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setData(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      // setPagination(prevPagination => ({ ...prevPagination, pageIndex: 1, totalPages: Math.ceil(responseData.totalCount / pagination.pageSize) }));
      setPagination({ pageIndex: 1, pageSize: 10, totalPages: 0 });
      setSearchValue("All Hotels");
      setShowDropdown("All Hotels");
      setSearchValueClient("All Clients");
      setShowDropdownClient("All Clients");
    } catch (error) {
      console.error("Error searching data:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSort = (column) => {
    setSorting((prevSorting) => ({
      column: column,
      direction: prevSorting.column === column && prevSorting.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    fetchData();
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value);
    const currentPageIndex = 1;
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageSize: newSize,
      pageIndex: currentPageIndex,
    }));
  };

  const renderPageRange = () => {
    const startIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;
    const endIndex = Math.min(startIndex + pagination.pageSize - 1, total);
    let filterText = "";
    if (showFilterMsg && filter != 0) {
      filterText = ` total ${total} (filtered from ${filter})`;
    } else {
      filterText = ` ${total}`;
    }
    return `Showing ${startIndex} to ${endIndex} of ${filterText} entries`;
  };

  const handleDeleteClick = (deleteItemId, type) => {
    setDeleteItemId(deleteItemId);
    setDeleteItemType(type);
    setIsDeleteModal(true);
  };

  const handleDelete = async (deleteItemId, type) => {
    // let deleteConfirm = prompt(`Do you want to delete this record. \nPlease enter "${type}"`);
    // if (deleteConfirm == type) {
    try {
      const response = await fetch(url + clientpoints.enquiry_delete, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({
          urlName: hotelId,
          filters: { _id: deleteItemId },
        }),
      });
      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Failed to delete item");
      }

      if (responseData.Status) {
        toast.success(responseData.Message);
      } else {
        toast.error(responseData.Message);
      }

      setData((prevData) => prevData.filter((item) => item._id !== deleteItemId));
      fetchData();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
    // } else {
    //   toast.error("Not Matched");
    // }
  };

  const handleQuoteClick = (item) => {
    setSelectedItem(item._id);
    setIsQuoteModalOpen(true);
  };

  const handleEditClick = (item) => {
    setSelectedItem(item._id);
    setIsModalOpen(true);
  };

  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
  const handleOpenModalAdd = () => {
    setIsModalOpenAdd(true);
  };

  return (
    <>
      <div className='inner-padding'>
        <div className='featured-flx'>
          <div className='featured-flx1'>
            <p className='main_heading1'>{name}</p>
          </div>

          <div className='featured-flx1'>
            {whoAmI === "team" ? (
              <>
                {typeTeamRoleObject?.enquiry?.add && (
                  <button className='circle-btn-add icon' type='button' onClick={handleOpenModalAdd}>
                    {" "}
                    {isLoading ? <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> : <i className='ri-add-line'></i>}
                  </button>
                )}
              </>
            ) : (
              <>
                <button className='circle-btn-add icon' type='button' onClick={handleOpenModalAdd}>
                  {" "}
                  {isLoading ? <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> : <i className='ri-add-line'></i>}
                </button>
              </>
            )}
          </div>
        </div>

        <div className='featured-flx1'>
          <div className='row' style={{width:"100%"}}>
            <div className='suport'>
              <div className='table-responsive mt-2 tablebord'>
                <div className='d-flex justify-content-between align-items-center mb-1'>
                  <div className='d-flex'>
                    <button onClick={handleRefresh} className='btn btn-primary'>
                      {isLoading ? <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> : <i className='ri-refresh-line'></i>}
                      &#160; Refresh
                    </button>
                    &#160;&nbsp;&nbsp;
                    <button className='btn btn-primary' onClick={handleHardRefresh}>
                      <i className='ri-gradienter-line'></i> Reset
                    </button>
                  </div>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <input type='text' placeholder='Search...' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} onKeyUp={handleSearchSubmit} className='search-input form-control search-inp' />
                    </div>
                  </div>
                </div>
                <div className='support_table'>
                  <div className='scroll_table-space'>
                    <Table hover table-responsive>
                      <thead>
                        <tr>
                          <th>S. No </th>
                          {/* <th>Company Name </th> */}
                          <th onClick={() => handleSort("name")}>
                            Name <FaFilter />
                            &nbsp; {sorting.column === "name" && (sorting.direction === "asc" ? <FaSortAmountUpAlt /> : <FaSortAmountDownAlt />)}
                          </th>
                          <th>Email </th>
                          <th>Phone </th>
                          <th>Enquiry Date</th>
                          <th>Next Follow Up Date</th>
                          {/* <th>Reason </th> */}
                          <th onClick={() => handleSort("createdAt")}>
                            Date <FaFilter />
                            &nbsp; {sorting.column === "createdAt" && (sorting.direction === "asc" ? <FaSortAmountUpAlt /> : <FaSortAmountDownAlt />)}
                          </th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <div className='d-flex justify-content-center align-items-center myload'>
                            <div className='spinner-border text-primary ' role='status'>
                              <span className='sr-only'>Loading...</span>
                            </div>
                          </div>
                        ) : data.length > 0 ? (
                          data.map((item, index) => (
                            <tr key={index}>
                              <td>{adjustedStartIndex + index}</td>
                              {/* <td>{item.client_id && item.client_id.name}</td>
                                <td>{item.hotel_id && item.hotel_id.hotel_name}</td> */}
                              {/* <td>{item.company_id && item.company_id.name}</td> */}
                              <td>{item.name}</td>
                              <td>{item.email}</td>
                              <td>{item.phone}</td>
                              <td>{item.enquirie_date}</td>
                              <td>{item.next_follow_up_date}</td>
                              {/* <td>{item.reason}</td> */}
                              <td>{new Date(item.createdAt).toLocaleString()}</td>
                              <td>
                                <div className='mybots'>
                                  {whoAmI === "team" ? (
                                    <>
                                      {typeTeamRoleObject?.quotation?.view && (
                                        <button title='Price Quote' variant='primary edit-btn' className='edit-btn1' onClick={() => handleQuoteClick(item)}>
                                          <i className='ri-space-ship-line'></i>{" "}
                                        </button>
                                      )}
                                      {typeTeamRoleObject?.enquiry?.view && (
                                        <button
                                          variant='primary edit-btn'
                                          className='edit-btn1'
                                          onClick={() => {
                                            setIsViewModal(true);
                                            setSelectedItem(item._id);
                                          }}
                                        >
                                          <i className='ri-eye-line'></i>
                                        </button>
                                      )}
                                      {typeTeamRoleObject?.enquiry?.edit && (
                                        <button variant='primary edit-btn' className='edit-btn1' onClick={() => handleEditClick(item)}>
                                          <i className='ri-pencil-line'></i>
                                        </button>
                                      )}
                                      {typeTeamRoleObject?.enquiry?.delete && (
                                        <button variant='danger edit-btn' className='delete-btn1' onClick={() => handleDeleteClick(item._id, item.name)}>
                                          <i className='ri-delete-bin-line'></i>
                                        </button>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <button title='Price Quote' variant='primary edit-btn' className='edit-btn1' onClick={() => handleQuoteClick(item)}>
                                        <i className='ri-space-ship-line'></i>{" "}
                                      </button>
                                      <button
                                        variant='primary edit-btn'
                                        className='edit-btn1'
                                        onClick={() => {
                                          setIsViewModal(true);
                                          setSelectedItem(item._id);
                                        }}
                                      >
                                        <i className='ri-eye-line'></i>
                                      </button>
                                      <button variant='primary edit-btn' className='edit-btn1' onClick={() => handleEditClick(item)}>
                                        <i className='ri-pencil-line'></i>
                                      </button>
                                      <button variant='danger edit-btn' className='delete-btn1' onClick={() => handleDeleteClick(item._id, item.name)}>
                                        <i className='ri-delete-bin-line'></i>
                                      </button>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className='middledata'>
                            <td colSpan={12}>
                              <div className='nodatafound1'>No data found</div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className='pagination-container d-flex justify-content-between align-items-center mt-1 page-flx1'>
                  <div className='mknk'>
                    <Form.Label>Rows per page:</Form.Label>
                    <select as='select' onChange={handlePageSizeChange} value={pagination.pageSize} className='dropon'>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                    </select>
                  </div>

                  <div className=' flex-pagination'>
                    <div>{renderPageRange()}</div>
                    <div className='mypage1'>
                      {pagination.pageIndex !== 1 && data.length !== 0 && (
                        <button
                          disabled={pagination.pageIndex === 1}
                          onClick={() =>
                            setPagination((prevPagination) => ({
                              ...prevPagination,
                              pageIndex: prevPagination.pageIndex - 1,
                            }))
                          }
                          class='button left-clickbtn'
                          id='button1'
                        >
                          <i className='ri-arrow-left-s-line'></i>
                        </button>
                      )}

                      <div class='button active' id='button1'>
                        {pagination.pageIndex}
                      </div>
                      {pagination.pageIndex !== pagination.totalPages && data.length !== 0 && (
                        <button
                          disabled={pagination.pageIndex === pagination.totalPages}
                          onClick={() =>
                            setPagination((prevPagination) => ({
                              ...prevPagination,
                              pageIndex: prevPagination.pageIndex + 1,
                            }))
                          }
                          class='button left-clickbtn'
                          id='button4'
                        >
                          <i className='ri-arrow-right-s-line'></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isDeleteModal && <ConfirmModal isModalOpen={isDeleteModal} setIsModalOpen={setIsDeleteModal} modalName='Confirm Deletion' deleteItemId={deleteItemId} setDeleteItemId={setDeleteItemId} deleteItemType={deleteItemType} setDeleteItemType={setDeleteItemType} handleDelete={handleDelete} />}

        {/* {isQuoteModalOpen && <AddQuoteModal isModalOpen={isQuoteModalOpen} setIsModalOpen={setIsQuoteModalOpen} modalName={"Add Price Quote"} onRefresh={handleRefresh} data={selectedItem} />} */}

        {isModalOpenAdd && <AddModalComponent isModalOpen={isModalOpenAdd} setIsModalOpen={setIsModalOpenAdd} modalName={"Quotation (Add)"} onRefresh={handleRefresh} />}

        {isModalOpen && <EditModalComponent isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} data={selectedItem} modalName={"Quotation"} onRefresh={handleRefresh} view='edit' />}

        {isViewModal && <EditModalComponent isModalOpen={isViewModal} setIsModalOpen={setIsViewModal} data={selectedItem} modalName={"Quotation"} onRefresh={handleRefresh} view='view' />}
      </div>
    </>
  );
};

export default Component;
