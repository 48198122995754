import { React, useState, useEffect, lazy } from "react";
import ReactDOM from "react-dom/client";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { getDecryptedDataFromLocalStorage, url } from "./lib/lib.js";
import { AuthUserContext } from "./lib/AuthUserContext.js";

// Start Website Route
import Home from "./website/screens/Home.js";
import Privacy from "./website/screens/Privacy.js";
import Terms from "./website/screens/Terms.js";
import NotFound from "./website/screens/NotFound.js";
import HotelsRoutes from "./panels/hotels/hotels.js";

import AdminRoute from "./panels/admin/admin.js";

import TeamLogin from "./website/screens/TeamLogin.js";

// import DashboardTemp from "./panels/nav/screens/Dashboard.js";
import DynamicWebisteRoute from "./dynamic_webiste/screens/Home.js";
// End Website Route
// import HotelsHome from "./panels/hotels/screens/Home.js";
import ClientRoutes from "./panels/client/client.js";
// import PrivateRoutes from "./utils/PrivateRoute.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

function App() {
  const [whoAmI, setWhoAmI] = useState(""); // client, team, member as a type
  const [typeTeamRoleObject, setTypeTeamRoleObject] = useState({}); //  Team Role Object
  const [hotelUrl, setHotelUrl] = useState("");

  useEffect(() => {
    const getLocalStorageData = getDecryptedDataFromLocalStorage("type");
    if (whoAmI === null && !getLocalStorageData) {
      window.location.reload(); // Refresh the page if both whoAmI and local storage data are null
    } else if (getLocalStorageData) {
      setWhoAmI(getLocalStorageData);
    }
  }, []);

  // Dynamic Route Rendering
  const domain_name = window.location.host;  
  // const domain_name = "test.codenap.in";  
  console.log('domain_name - ', domain_name);

  let domain_flag = true;

  if(domain_name == 'staystation.encrobytes.com' || domain_name == "localhost:3000")
  {
    domain_flag = false;
  }

  if(domain_flag){
    fetch(url + "/hotel/views", {
      method: "POST",
      body: JSON.stringify({ domain: domain_name }),
      headers: {
        // authorization: "bdjsbdsjs7bHR@4567",
        // key: "jsjdj744%5GSHr45",
        "Content-Type": "application/json",
      },
    }).then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log('oko - ',data);
      setHotelUrl(data.Data);
      // let Main = lazy(() => import("./panels/nav/screens/Dashboard.js"));
      
      
    });

    return (
      <>
        <AuthUserContext.Provider value={{ whoAmI, setWhoAmI, typeTeamRoleObject, setTypeTeamRoleObject, hotelUrl, setHotelUrl }}>
          <Router>
            <Routes>
              <Route path='/' element={<DynamicWebisteRoute />} />
              <Route path='/hotel/:hotelId/admin/login' element={<TeamLogin />} />
              <Route path='/hotel/:hotelId/admin/*' element={<HotelsRoutes />} />
              {/* <Route exact path='/' element={<Main />} /> */}
              <Route path="*" element={<NotFound />}/>
            </Routes>
          </Router>
        </AuthUserContext.Provider>
      </>
    );
    
  }
  else{
    
    return (
      <>
        <AuthUserContext.Provider value={{ whoAmI, setWhoAmI, typeTeamRoleObject, setTypeTeamRoleObject }}>
          <Router>
            <Routes>
              <Route path='Br!Y7o&p45fM/portal/*' element={<AdminRoute />} />
              <Route path='/*' element={<ClientRoutes />} />
              <Route path='/hotel/:hotelId' element={<DynamicWebisteRoute />} />
              <Route path='/hotel/:hotelId/admin/login' element={<TeamLogin />} />
              <Route path='/hotel/:hotelId/admin/*' element={<HotelsRoutes />} />
              <Route exact path='/' element={<Home />} />
              <Route exact path='/privacy' element={<Privacy />} />
              <Route exact path='/terms' element={<Terms />} />
              <Route path="*" element={<NotFound />}/>
            </Routes>
          </Router>
        </AuthUserContext.Provider>
      </>
    );
  }
    

  // Dynamic Route Rendering

}

root.render(<App />);
