import React, { useMemo, useState, useEffect } from "react";
import { Button, Table, Form } from "react-bootstrap";
import { url, adminpoints, adminHeader, admin_prefix } from "../../../../../lib/lib.js";
import { FaEdit, FaTrashAlt, FaSyncAlt, FaSort, FaSearch, FaDna, FaFilter, FaSortUp, FaSortDown, FaSortAmountUpAlt, FaSortAmountDownAlt } from "react-icons/fa";
import ChatComponent from "../chatting/Component.js";
import Toogle from "../edit_modal/Component.js";
import { toast } from "react-toastify";
import AddModalComponent from "../add_modal/Component.js";
import "./component.css";
import { Link, useNavigate } from "react-router-dom";
import SearchableSelectComponentHotel from "../../subscription_ui/hotel_select/Component.js";
import SearchableSelectComponentClient from "../../subscription_ui/client_select/Component.js";

const Component = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const filterFromUrl = urlParams.get("filter");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(0);
  const [showFilterMsg, setShowFilterMsg] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hotelList, setHotelList] = useState([]);

  const [hotelItem, setHotelItem] = useState(null);
  const [clientItem, setClientItem] = useState(null);
  const [chatItem, setChatItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [createdAt, setCreatedAt] = useState("");

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
    totalPages: 0,
  });

  const [sorting, setSorting] = useState({ column: null, direction: "desc" });
  const adjustedStartIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;

  const [searchTerm, setSearchTerm] = useState("");

  const [clientName, setClientName] = useState("");
  const [hotelName, setHotelName] = useState("");
  const [ticketStatus, setTicketStatus] = useState(true);

  // For Select Dropdown
  const [searchValueClient, setSearchValueClient] = useState("All Clients");
  const [suggestionsClient, setSuggestionsClient] = useState([]);
  const [showDropdownClient, setShowDropdownClient] = useState("All Clients");

  const [searchValue, setSearchValue] = useState("All Hotels");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState("All Hotels");

  const fetchData = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const sortingObject = sorting.column ? { [sorting.column]: sorting.direction === "asc" ? 1 : -1 } : { createdAt: "-1" };

      let obj = {
        filters: {
          ...(hotelName && { hotel_id: hotelName }),
          ...(clientName && { client_id: clientName }),
          ...(ticketStatus && { status: ticketStatus }),
        },
      };

      const requestBody = {
        ...obj,
        search: searchTerm,
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
      };

      const response = await fetch(url + adminpoints.client_ticket_view, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }
      const responseData = await response.json();
      setData(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      setPagination((prevPagination) => ({
        ...prevPagination,
        totalPages: Math.ceil(responseData.totalCount / pagination.pageSize),
      }));
    } catch (error) {
      setIsError(true);
      navigate(`${admin_prefix}login`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // if (filterFromUrl) {
    //     setTicketStatus(filterFromUrl);
    // }
    fetchData();
    fetchHotelData();
  }, [hotelName, clientName, ticketStatus, pagination.pageIndex, pagination.pageSize, sorting.column, sorting.direction]);

  const handleRefresh = () => {
    fetchData();
  };

  const handleHardRefresh = async () => {
    try {
      setIsLoading(true);
      const sortingObject = {};
      setSearchTerm("");
      setHotelName("");
      setClientName("");
      const requestBody = {
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
      };
      const response = await fetch(url + adminpoints.client_ticket_view, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }

      const responseData = await response.json();
      setData(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      // setPagination(prevPagination => ({ ...prevPagination, pageIndex: 1, totalPages: Math.ceil(responseData.totalCount / pagination.pageSize) }));
      setPagination({ pageIndex: 1, pageSize: 10, totalPages: 0 });
      setSearchValue("All Hotels");
      setShowDropdown("All Hotels");
      setSearchValueClient("All Clients");
      setShowDropdownClient("All Clients");
      setTicketStatus(true);
    } catch (error) {
      setIsError(true);
      navigate(`${admin_prefix}login`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSort = (column) => {
    setSorting((prevSorting) => ({
      column: column,
      direction: prevSorting.column === column && prevSorting.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    fetchData();
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value);
    const currentPageIndex = 1;
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageSize: newSize,
      pageIndex: currentPageIndex,
    }));
  };

  const renderPageRange = () => {
    const startIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;
    const endIndex = Math.min(startIndex + pagination.pageSize - 1, total);
    let filterText = "";
    if (showFilterMsg && filter != 0) {
      filterText = ` total ${total} (filtered from ${filter})`;
    } else {
      filterText = ` ${total}`;
    }
    return `Showing ${startIndex} to ${endIndex} of ${filterText} entries`;
  };

  const handleDelete = async (deleteItemId, type) => {
    let deleteConfirm = prompt(`Do you want to delete this record. \nPlease enter "${type}"`);
    if(deleteConfirm !=  null){
      if (deleteConfirm == type) {
        try {
          const response = await fetch(url + adminpoints.client_ticket_delete, {
            method: "POST",
            headers: adminHeader,
            credentials: "include",
            body: JSON.stringify({ filters: { _id: deleteItemId } }),
          });

          if (!response.ok) {
            navigate(`${admin_prefix}login`);
          }

          const responseData = await response.json();
          if (responseData.Status) {
            await fetch(url + adminpoints.client_ticket_history_delete, {
              method: "POST",
              headers: adminHeader,
              credentials: "include",
              body: JSON.stringify({ filters: { client_ticket_id: deleteItemId } }),
            });
            toast.success(responseData.Message);
          } else {
            toast.error(responseData.Message);
          }

          setData((prevData) => prevData.filter((item) => item._id !== deleteItemId));
          handleRefresh();
        } catch (error) {
          navigate(`${admin_prefix}login`);
        }
      } else {
        toast.error("Not Matched");
      }
    }
  };

  const handleChatModel = (item) => {
    setChatItem(item._id);
    setClientItem(item.client_id);
    setHotelItem(item.hotel_id);
    setCreatedAt(item.createdAt);
    setIsChatOpen(true);
  };

  const fetchHotelData = async () => {
    try {
      const response = await fetch(url + adminpoints.hotel_view, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
      });
      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }
      const data = await response.json();
      if (data.Status) {
        setHotelList(data.Data);
      }
    } catch (error) {
      navigate(`${admin_prefix}login`);
    }
  };

  const handleOpenAndCloseTickets = (event) => {
    setTicketStatus(event.target.value);
  };
  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);
  const handleOpenModalAdd = () => {
    setIsModalOpenAdd(true);
  };

  return (
    <>
      <div className='new1'>
        <div className='featured-flx'>
          <div className='featured-flx1'>
            <h3>All Support</h3>
          </div>

          <div className='featured-flx1'>
            <button className='circle-btn-add icon' type='button' onClick={handleOpenModalAdd}>
              <i className='ri-add-line'></i>
            </button>
          </div>
        </div>
      </div>

      <div className=' justify-content-between align-items-center '>
        <div className='d-flex cvc'>
          <div className='sp1 d-flex flex-column me-3'>
            <label htmlFor='clientName'>Client:</label>
            <div className='nextd'>
              <SearchableSelectComponentClient
                setClientName={setClientName}
                setHotelName={setHotelName}
                setPagination={setPagination}
                searchValueProps={{ searchValueClient, setSearchValueClient }}
                suggestionsProps={{ suggestionsClient, setSuggestionsClient }}
                showDropdownProps={{ showDropdownClient, setShowDropdownClient }}
                searchValueProps_s={{ searchValue, setSearchValue }}
                suggestionsProps_s={{ suggestions, setSuggestions }}
                showDropdownProps_s={{ showDropdown, setShowDropdown }}
              />
            </div>
          </div>

          <div className='sp2 d-flex flex-column me-3'>
            <label htmlFor='clientName'>Hotel:</label>
            <div className='nextd'>
              <SearchableSelectComponentHotel
                setHotelName={setHotelName}
                clientName={clientName}
                setPagination={setPagination}
                searchValueProps={{ searchValue, setSearchValue }}
                suggestionsProps={{ suggestions, setSuggestions }}
                showDropdownProps={{ showDropdown, setShowDropdown }}
              />
            </div>
          </div>

          <div className='sp3 d-flex flex-column me-3'>
            <label htmlFor='clientName'>Ticket Status:</label>
            <div className='nextd'>
              <select id='hotelName' className={`form-select`} value={ticketStatus} onChange={handleOpenAndCloseTickets}>
                <option value=''>All Tickets</option>
                <option value='true'>Open Tickets</option>
                <option value='false'>Closed Tickets</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className='suport'>
        <div className='table-responsive mt-2 tablebord'>
          <div className='d-flex justify-content-between align-items-center m-1'>
            <div className='d-flex'>
              <button onClick={handleRefresh} className='desk-refresh'>
                {isLoading ? (
                  <>
                    <span className='spinner-border spinner-border-sm abs' role='status' aria-hidden='true'></span> <i className='ri-refresh-line hidd'></i>
                  </>
                ) : (
                  <i className='ri-refresh-line'></i>
                )}
                &#160;
                {/* {isLoading ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : (<i className="ri-refresh-line"></i>)}&#160; */}
                Refresh
              </button>
              &#160;&nbsp;&nbsp;
              <button className='btn btn-primary' onClick={handleHardRefresh}>
                <i className='ri-gradienter-line'></i> Reset
              </button>
            </div>

            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex justify-content-between align-items-center'>
                <input
                  type='text'
                  placeholder='Search...'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyUp={handleSearchSubmit}
                  className='search-input form-control search-inp'
                />
              </div>
            </div>
          </div>
          <div className='support_table'>
            <div className='scroll_table-hotel'>
              <Table hover table-responsive>
                <thead>
                  <tr>
                    <th>S. No. </th>
                    <th>Client Name </th>
                    <th>Hotel Name </th>
                    <th onClick={() => handleSort("title")}>
                      Name <FaFilter />
                      &nbsp; {sorting.column === "title" && (sorting.direction === "asc" ? <FaSortAmountDownAlt /> : <FaSortAmountUpAlt />)}
                    </th>
                    <th>Status </th>
                    <th onClick={() => handleSort("createdAt")}>
                      Date <FaFilter />
                      &nbsp; {sorting.column === "createdAt" && (sorting.direction === "asc" ? <FaSortAmountDownAlt /> : <FaSortAmountUpAlt />)}
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colspan={8}>
                        <div className='d-flex justify-content-center align-items-center'>
                          <div className='spinner-border text-primary ' role='status'>
                            {/* <span className='sr-only'>Loading...</span> */}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>{adjustedStartIndex + index}</td>
                        <td>{item.client_id && item.client_id.name}</td>
                        <td>{item.hotel_id && item.hotel_id.hotel_name}</td>
                        <td>{item.title}</td>
                        <td>{item.status ? "Open" : "Closed"}</td>
                        <td>{new Date(item.createdAt).toLocaleString()}</td>
                        <td>
                          <div className='mybots'>
                            <button variant='primary edit-btn' className='edit-btn1' onClick={() => handleChatModel(item)}>
                              <i className='ri-chat-1-fill'></i>
                            </button>
                            {/* <Button variant="primary edit-btn" onClick={() => handleEditClick(item)}><i className="ri-pencil-line"></i> </Button> */}
                            <button variant='danger edit-btn' className='delete-btn1' onClick={() => handleDelete(item._id, item.title)}>
                              <i className='ri-delete-bin-line'></i>{" "}
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className='middledata'>
                      <td colSpan={7}>
                        <div className='nodatafound1'>No data found</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
          <div className='pagination-container d-flex justify-content-between align-items-center m-1 page-flx1'>
            <div className='mknk'>
              <Form.Label>Rows per page:</Form.Label>
              <select as='select' onChange={handlePageSizeChange} value={pagination.pageSize} className='form-select form-control dropon'>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={20}>25</option>
                <option value={20}>30</option>
                <option value={20}>50</option>
                <option value={20}>100</option>
                <option value={20}>200</option>
                <option value={20}>500</option>
                <option value={20}>1000</option>
              </select>
            </div>

            <div className=' flex-pagination'>
              <div className="mknk-pagignation">{renderPageRange()}</div>
              <div className='mypage1'>
                {pagination.pageIndex !== 1 && data.length !== 0 && (
                  <button
                    disabled={pagination.pageIndex === 1}
                    onClick={() => setPagination((prevPagination) => ({ ...prevPagination, pageIndex: prevPagination.pageIndex - 1 }))}
                    class='button left-clickbtn'
                    id='button1'
                  >
                    <i className='ri-arrow-left-s-line'></i>
                  </button>
                )}

                <div class='button active' id='button1'>
                  {pagination.pageIndex}
                </div>
                {pagination.pageIndex !== pagination.totalPages && data.length !== 0 && (
                  <button
                    disabled={pagination.pageIndex === pagination.totalPages}
                    onClick={() => setPagination((prevPagination) => ({ ...prevPagination, pageIndex: prevPagination.pageIndex + 1 }))}
                    class='button left-clickbtn'
                    id='button4'
                  >
                    <i className='ri-arrow-right-s-line'></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddModalComponent isModalOpen={isModalOpenAdd} setIsModalOpen={setIsModalOpenAdd} modalName={"Add New Ticket"} onRefresh={handleRefresh} />
      {isChatOpen && (
        <ChatComponent isChatOpen={isChatOpen} setIsChatOpen={setIsChatOpen} createdAt={createdAt} clientItem={clientItem} hotelItem={hotelItem} chatItem={chatItem} modalName={"Chat Support"} />
      )}
    </>
  );
};

export default Component;
