import React, { useState, useEffect, useContext } from "react";
import { clientpoints, url, whoAmIHeaders, getDecryptedDataFromLocalStorage, client_prefix } from "../../../../../../lib/lib.js";
import { AuthUserContext } from "../../../../../../lib/AuthUserContext.js";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "./component.css";
import Select from "react-select";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh, modalName, role = [], property = [], view = "view" }) {
  const { hotelId } = useParams();

  const navigate = useNavigate();

  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const initialFormData = {
    name: "",
    email: "",
    phone: "",
    password: "",
    role_id: "",
    property_id: [],
  };

  const [formData, setFormData] = useState(initialFormData);
  const [mode, setMode] = useState(view);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const [date, setDate] = useState("");
  const [current_id, setCurrent_id] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  let roleId = data;

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    setErrors(errors);
    return isValid;
  };

  const [loading, setLoading] = useState(false);

  const [selectValues, setSelectValues] = useState({
    role_id: "",
    property_id: "",
  });

  useEffect(() => {
    const roleObj = role.find((item) => item.value === formData.role_id);
    const role_id = roleObj ? roleObj : "";
    setSelectValues((prev) => ({ ...prev, role_id }));
  }, [formData.role_id]);

  useEffect(() => {
    const propertyObj = property.find((item) => item.value === formData.property_id);
    const property_id = propertyObj ? propertyObj : "";
    setSelectValues((prev) => ({ ...prev, property_id }));
  }, [formData.property_id]);

  useEffect(() => {
    if (isModalOpen && roleId) {
      setLoading(true);
      fetchData(roleId).then(() => {
        setLoading(false);
      });
    }
  }, [isModalOpen, roleId]);

  const fetchData = async (id) => {
    try {
      const response = await fetch(url + clientpoints.team_view, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify({ urlName: hotelId, filters: { _id: id } }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch role data");
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];
        setDate(response_data);
        setCurrent_id(response_data._id);
        setFormData({
          ...formData,
          ...response_data,
        });
      }
    } catch (error) {
      console.error("Error fetching role data:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(validateForm());
    if (validateForm()) {
      try {
        setIsSubmitting(true);
        console.log(11);
        const response = await fetch(url + clientpoints.team_edit, {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({
            urlName: hotelId,
            filters: { _id: current_id },
            data: {
              ...formData,
            },
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to submit data");
        }

        const responseData = await response.json();

        if (responseData.Status) {
          setIsModalOpen(false);
          onRefresh();
          // alert("Data submitted successfully!");
          toast.success(responseData.Message);
        } else {
          toast.error(responseData.Message);
          throw new Error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        // console.error('Error submitting data:', error.message);
        // alert('Failed to submit data. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  {mode === "view" ? `${modalName} (View)` : `${modalName} (Edit)`}
                </h5>
              {mode === "view" && (
                <button className='ms-auto btn btn-primary ' onClick={() => setMode("edit")}>
                <i class="ri-edit-box-line"></i>
                </button>
              )}
                <button type='button' className='btn btn-danger' onClick={handleCloseModal} style={{marginLeft:"8px"}}>
                 <i class="ri-close-line"></i>
                </button>
              </div>
              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className='modal-body text-center'>
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className='modal-body' style={{ maxHeight: "60vh", overflowY: "scroll" }}>
                    <div className='row'>
                      <label className='col-12 mb-3'>
                        Role Type
                        <Select
                          isDisabled={mode === "view"}
                          options={role}
                          value={role.find((option) => option.value === (formData.role_id && formData.role_id._id))}
                          onChange={(val) => setFormData((prev) => ({ ...prev, role_id: val.value }))}
                          placeholder='Select Role...'
                          className='flex-1 w-full'
                          styles={customStyles}
                          menuPortalTarget={document.body}
                        />
                      </label>
                      <label className='col-12 mb-3'>
                        Property Type
                        <Select
                          isDisabled={mode === "view"}
                          options={property}
                          closeMenuOnSelect={false}
                          isMulti
                          value={formData.property_id.map((id) => property.find((loc) => loc.value === id || loc.value === id._id))}
                          onChange={(selectedOptions) => setFormData((prev) => ({ ...prev, property_id: selectedOptions.map((option) => option.value) }))}
                          placeholder='Select Property...'
                          styles={customStyles}
                          menuPortalTarget={document.body}
                        />
                      </label>
                      <label className='col-md-6 mb-3'>
                        Name
                        <input disabled={mode === "view"} type='text' name='name' value={formData.name} onChange={handleChange} className='flex-1 w-full p-2 rounded-md outline-none border disabled:bg-gray-100' />
                      </label>
                      <label className='col-md-6 mb-3'>
                        Email
                        <input disabled={mode === "view"} type='text' name='email' value={formData.email} onChange={handleChange} className='flex-1 w-full p-2 rounded-md outline-none border disabled:bg-gray-100' />
                      </label>
                      <label className='col-md-6 mb-3'>
                        Phone
                        <input disabled={mode === "view"} type='text' name='phone' value={formData.phone} onChange={handleChange} className='flex-1 w-full p-2 rounded-md outline-none border disabled:bg-gray-100' />
                      </label>
                      <label className='col-md-6 mb-3'>
                        Password
                        <input disabled={mode === "view"} type='text' name='password' value={formData.password} onChange={handleChange} className='flex-1 w-full p-2 rounded-md outline-none border disabled:bg-gray-100' />
                      </label>
                    </div>
                  </div>
                )}
                <div className='modal-footer d-flex justify-content-between align-items-center'>
                  <p className='float-start'>CreatedAt : {new Date(date.createdAt).toLocaleDateString()}</p>
                  <div className='d-flex gap-2'>
                    {mode !== "view" && (
                      <>
                        {/* <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                          Close
                        </button> */}
                        {/* <button type="submit" className="btn btn-primary">Update</button> */}
                        <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                          {isSubmitting ? "Loading..." : "Update"}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
    maxHeight: "200px",
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: "200px",
  }),
};
