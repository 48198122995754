import React, { useMemo, useState, useEffect, useRef } from "react";
import { Button, Table, Form } from "react-bootstrap";
import { url, adminpoints, adminHeader, admin_prefix } from "../../../../../lib/lib.js";
import { useNavigate } from "react-router-dom";
import { FaEdit, FaTrashAlt, FaSyncAlt, FaSort, FaSearch, FaDna, FaFilter, FaSortUp, FaSortDown, FaSortAmountUpAlt, FaSortAmountDownAlt } from "react-icons/fa";
import EditModalComponent from "../edit_modal/Component.js";
import { toast } from "react-toastify";
// import AddModalComponent from "../components/subscription_ui/add_modal/Component.js";
import AddModalComponent from "../add_modal/Component.js";
import SearchableSelectComponentHotel from "../hotel_select/Component.js";
import SearchableSelectComponentClient from "../client_select/Component.js";

import "./component.css";

const Component = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const idFromUrl = urlParams.get("id");

  // for loading
  const [isLoading, setIsLoading] = useState(false);

  // for fetch data set
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [getid, setGetId] = useState("");

  // for selected item edit
  const [selectedItem, setSelectedItem] = useState(null);

  // for pagination
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
    totalPages: 0,
  });
  const adjustedStartIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;

  // for filter
  const [filter, setFilter] = useState(0);
  const [showFilterMsg, setShowFilterMsg] = useState(false);

  // for sorting
  const [sorting, setSorting] = useState({ column: null, direction: "desc" });

  // for search
  const [searchTerm, setSearchTerm] = useState("");

  // For Client Select Dropdown
  const [clientName, setClientName] = useState("");
  const [searchValueClient, setSearchValueClient] = useState("All Clients");
  const [suggestionsClient, setSuggestionsClient] = useState([]);
  const [showDropdownClient, setShowDropdownClient] = useState("All Clients");

  // For Hotel Select Dropdown
  const initialAccountStatus = idFromUrl !== null && idFromUrl.trim() ? "" : idFromUrl;
  const [hotelName, setHotelName] = useState(initialAccountStatus);

  const [searchValue, setSearchValue] = useState("All Hotels");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState("All Hotels");

  // for add modal
  const [isModalOpenAdd, setIsModalOpenAdd] = useState(false);

  // for edit modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  // All event handler Start
  const fetchData = async (tempGetid = "") => {
    setIsLoading(true);
    try {
      const sortingObject = sorting.column ? { [sorting.column]: sorting.direction === "asc" ? 1 : -1 } : { createdAt: "-1" };

      let filters;
      if (tempGetid !== null) {
        filters = {
          ...(tempGetid !== null && tempGetid.trim() && { hotel_id: tempGetid }),
          ...(hotelName && { hotel_id: hotelName }),
          ...(clientName && { client_id: clientName }),
        };
      } else {
        filters = {
          ...(hotelName && { hotel_id: hotelName }),
          ...(clientName && { client_id: clientName }),
        };
      }

      const requestBody = {
        filters,
        search: searchTerm,
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
      };

      const response = await fetch(url + adminpoints.subscription_view, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }

      const responseData = await response.json();
      setData(responseData.Data);
      setTotal(responseData.totalCount);
      setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      setPagination((prevPagination) => ({
        ...prevPagination,
        totalPages: Math.ceil(responseData.totalCount / pagination.pageSize),
      }));

      if (tempGetid !== null && tempGetid.trim() != "" && responseData.Data.length > 0) {
        const clientData = responseData.Data[0];
        if (tempGetid === clientData.hotel_id._id) {
          // setClientName(clientData.client_id._id);
          setSearchValueClient(clientData.client_id.name);
          setShowDropdownClient(clientData.client_id.name);
          setSearchValue(clientData.hotel_id.hotel_name);
          setShowDropdown(clientData.hotel_id.hotel_name);
        }
      }
    } catch (error) {
      navigate(`${admin_prefix}login`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefresh = () => {
    if (idFromUrl) {
      fetchData(idFromUrl);
    } else {
      fetchData();
    }
  };

  const handleHardRefresh = async () => {
    if (idFromUrl) {
      navigate(admin_prefix + "subscription");
    }

    try {
      setIsLoading(true);
      setGetId("");
      setSuggestions([]);
      const sortingObject = { createdAt: "-1" };

      const requestBody = {
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sorting: sortingObject,
      };
      const response = await fetch(url + adminpoints.subscription_view, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }

      const responseData = await response.json();
      setData(responseData.Data);
      setTotal(responseData.totalCount);
      // setFilter(responseData.totalCountFilters);
      if (responseData.totalCountFilters != 0) {
        setShowFilterMsg(true);
      }
      // setPagination(prevPagination => ({ ...prevPagination, pageIndex: 1, totalPages: Math.ceil(responseData.totalCount / pagination.pageSize) }));
      setPagination({ pageIndex: 1, pageSize: 10, totalPages: 0 });
      setSearchValue("All Hotels");
      setShowDropdown("All Hotels");
      setSearchValueClient("All Clients");
      setShowDropdownClient("All Clients");
      setSearchTerm("");
      setHotelName("");
      setClientName("");
      // if (idFromUrl) {
      //   navigate(admin_prefix + "subscription");
      // }
    } catch (error) {
      setIsLoading(false);
      navigate(`${admin_prefix}login`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSort = (column) => {
    setSorting((prevSorting) => ({
      column: column,
      direction: prevSorting.column === column && prevSorting.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    fetchData();
  };

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value);
    const currentPageIndex = 1;
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageSize: newSize,
      pageIndex: currentPageIndex,
    }));
  };

  const renderPageRange = () => {
    const startIndex = (pagination.pageIndex - 1) * pagination.pageSize + 1;
    const endIndex = Math.min(startIndex + pagination.pageSize - 1, total);
    let filterText = "";
    if (showFilterMsg && filter != 0) {
      filterText = ` total ${total} (filtered from ${filter})`;
    } else {
      filterText = ` ${total}`;
    }
    return `Showing ${startIndex} to ${endIndex} of ${filterText} entries`;
  };

  const handleOpenModalAdd = () => {
    setIsModalOpenAdd(true);
  };

  const handleEditClick = (item) => {
    setSelectedItem(item._id);
    setIsModalOpen(true);
  };

  const handleDelete = async (deleteItemId, type, hotel_id) => {
    let deleteConfirm = prompt(`Do you want to delete this record. \nPlease enter "${type}"`);
    if(deleteConfirm !=  null){
      if (deleteConfirm == type) {
        try {
          const response = await fetch(url + adminpoints.subscription_delete, {
            method: "POST",
            headers: adminHeader,
            credentials: "include",
            body: JSON.stringify({ filters: { _id: deleteItemId, hotel_id } }),
          });
          if (!response.ok) {
            navigate(`${admin_prefix}login`);
          }
          
          let responseData = await response.json();
          setData((prevData) => prevData.filter((item) => item._id !== deleteItemId));
          handleRefresh();
          console.log(responseData);
          
          if(responseData.Status){
            toast.success(responseData.Message);
          }else{
            toast.warn(responseData.Message);
          }
        } catch (error) {
          navigate(`${admin_prefix}login`);
        }
      } else {
        toast.error("Not Matched");
      }
    }
  };
  // All event handler End

  // useEffect Start
  useEffect(() => {
    if (idFromUrl) {
      // setGetId(idFromUrl);
      fetchData(idFromUrl);
    } else {
      fetchData();
    }
  }, [idFromUrl, searchTerm, clientName, hotelName, pagination.pageIndex, pagination.pageSize, sorting.column, sorting.direction]);
  // useEffect End

  return (
    <>
      <div className='new1'>
        <div className='featured-flx'>
          <div className='featured-flx1'>
            <h3>Subscription History</h3>
          </div>
          <div className='featured-flx1'>
            <button className='circle-btn-add icon' type='button' onClick={handleOpenModalAdd}>
              <i className='ri-add-line'></i>
            </button>
          </div>
        </div>
      </div>
      <div className='  justify-content-between align-items-center'>
        <div className='d-flex'>
          <div className='sub1 d-flex flex-column me-3'>
            <label htmlFor='clientName'>Client:</label>
            <div className='nextd'>
              <SearchableSelectComponentClient
                setClientName={setClientName}
                setHotelName={setHotelName}
                setPagination={setPagination}
                searchValueProps={{ searchValueClient, setSearchValueClient }}
                suggestionsProps={{ suggestionsClient, setSuggestionsClient }}
                showDropdownProps={{ showDropdownClient, setShowDropdownClient }}
                searchValueProps_s={{ searchValue, setSearchValue }}
                suggestionsProps_s={{ suggestions, setSuggestions }}
                showDropdownProps_s={{ showDropdown, setShowDropdown }}
              />
            </div>
          </div>

          <div className='sub2 d-flex flex-column me-3'>
            <label htmlFor='clientName'>Hotel:</label>
            <div className='nextd'>
              <SearchableSelectComponentHotel
                setHotelName={setHotelName}
                clientName={clientName}
                setPagination={setPagination}
                searchValueProps={{ searchValue, setSearchValue }}
                suggestionsProps={{ suggestions, setSuggestions }}
                showDropdownProps={{ showDropdown, setShowDropdown }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='subscription_table'>
        <div className='table-responsive mt-2 tablebord'>
          <div className='hotel_filter m-1'>
            <div className='d-flex'>
              <button onClick={handleRefresh} className='desk-refresh'>
                {isLoading ? (
                  <>
                    <span className='spinner-border spinner-border-sm abs' role='status' aria-hidden='true'></span> <i className='ri-refresh-line hidd'></i>
                  </>
                ) : (
                  <i className='ri-refresh-line'></i>
                )}
                &#160;
                {/* {isLoading ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : (<i className="ri-refresh-line"></i>)}&#160; */}
                Refresh
              </button>
              &#160;&nbsp;&nbsp;
              <button className='btn btn-primary' onClick={handleHardRefresh}>
                <i className='ri-gradienter-line'></i> Reset
              </button>
            </div>

            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex justify-content-between align-items-center'>
                <input
                  type='text'
                  placeholder='Search...'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyUp={handleSearchSubmit}
                  className='search-input form-control search-inp'
                />
              </div>
            </div>
          </div>
          <div className='subscription'>
            <div className='scroll_table-hotel'>
              <Table hover>
                <thead>
                  <tr>
                    <th>S. No. </th>
                    <th>Client Name </th>
                    <th>Hotel Name </th>
                    {/* <th onClick={() => handleSort('client_id')}>Client Name <FaFilter />&nbsp; {sorting.column === 'client_id' && (sorting.direction === 'asc' ? <FaSortAmountDownAlt /> : <FaSortAmountUpAlt />)}</th> */}
                    {/* <th onClick={() => handleSort('hotel_id')}>Hotel Name <FaFilter />&nbsp; {sorting.column === 'hotel_id' && (sorting.direction === 'asc' ? <FaSortAmountDownAlt /> : <FaSortAmountUpAlt />)}</th> */}
                    <th onClick={() => handleSort("subscription_name")}>
                      Subscription Name <FaFilter />
                      &nbsp; {sorting.column === "subscription_name" && (sorting.direction === "asc" ? <FaSortAmountDownAlt /> : <FaSortAmountUpAlt />)}
                    </th>
                    <th onClick={() => handleSort("subscription_desk")}>
                      Rooms <FaFilter />
                      &nbsp; {sorting.column === "subscription_desk" && (sorting.direction === "asc" ? <FaSortAmountDownAlt /> : <FaSortAmountUpAlt />)}
                    </th>
                    <th onClick={() => handleSort("base_amount")}>
                      Amount <FaFilter />
                      &nbsp; {sorting.column === "base_amount" && (sorting.direction === "asc" ? <FaSortAmountDownAlt /> : <FaSortAmountUpAlt />)}
                    </th>
                    <th onClick={() => handleSort("createdAt")}>
                      Start Date <FaFilter />
                      &nbsp; {sorting.column === "createdAt" && (sorting.direction === "asc" ? <FaSortAmountDownAlt /> : <FaSortAmountUpAlt />)}
                    </th>
                    <th onClick={() => handleSort("subscription_validity")}>
                      End Date <FaFilter />
                      &nbsp; {sorting.column === "subscription_validity" && (sorting.direction === "asc" ? <FaSortAmountDownAlt /> : <FaSortAmountUpAlt />)}
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colspan={9}>
                        <div className='d-flex justify-content-center align-items-center'>
                          <div className='spinner-border text-primary ' role='status'>
                            {/* <span className='sr-only'>Loading...</span> */}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>{adjustedStartIndex + index}</td>
                        <td>{item.client_id && item.client_id.name}</td>
                        <td>{item.hotel_id && item.hotel_id.hotel_name}</td>
                        <td>{item.subscription_name}</td>
                        <td>{item.subscription_rooms}</td>
                        <td>{item.base_amount}</td>
                        <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                        <td>{new Date(item.subscription_validity).toLocaleDateString()}</td>
                        <td>
                          <div className='mybots'>
                            <button variant='primary edit-btn' className='edit-btn1' onClick={() => handleEditClick(item)}>
                              <i className='ri-pencil-line'></i>{" "}
                            </button>
                            <button variant='danger edit-btn' className='delete-btn1' onClick={() => handleDelete(item._id, item.subscription_name, item.hotel_id)}>
                              <i className='ri-delete-bin-line'></i>{" "}
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className='middledata'>
                      <td colSpan={9}>
                        <div className='nodatafound1'>No data found</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>

          <div className='pagination-container d-flex justify-content-between align-items-center m-1 page-flx1'>
            <div className='mknk'>
              <Form.Label>Rows per page:</Form.Label>
              <select as='select' onChange={handlePageSizeChange} value={pagination.pageSize} className='form-select form-control drpon'>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={500}>500</option>
                <option value={1000}>1000</option>
              </select>
            </div>

            <div className=' flex-pagination'>
              <div className="mknk-pagignation">{renderPageRange()}</div>
              <div className='mypage1'>
                {pagination.pageIndex !== 1 && (
                  <button
                    disabled={pagination.pageIndex === 1}
                    onClick={() => setPagination((prevPagination) => ({ ...prevPagination, pageIndex: prevPagination.pageIndex - 1 }))}
                    class='button left-clickbtn'
                    id='button1'
                  >
                    <i className='ri-arrow-left-s-line'></i>
                  </button>
                )}

                <div class='button active' id='button1'>
                  {pagination.pageIndex}
                </div>

                {pagination.pageIndex !== pagination.totalPages && (
                  <button
                    disabled={pagination.pageIndex === pagination.totalPages}
                    onClick={() => setPagination((prevPagination) => ({ ...prevPagination, pageIndex: prevPagination.pageIndex + 1 }))}
                    class='button left-clickbtn'
                    id='button4'
                  >
                    <i className='ri-arrow-right-s-line'></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isModalOpenAdd && <AddModalComponent isModalOpen={isModalOpenAdd} setIsModalOpen={setIsModalOpenAdd} modalName={"Add New Subscription"} onRefresh={handleRefresh} />}

      {isModalOpen && <EditModalComponent isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} data={selectedItem} onRefresh={handleRefresh} />}
    </>
  );
};

export default Component;
