import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./component.css";
import { endpoints, teampoints, teamHeader, url, clientpoints, setEncryptedDataToLocalStorage, getDecryptedDataFromLocalStorage } from "../../../lib/lib.js";
import { AuthUserContext } from "../../../lib/AuthUserContext.js";

const Component = () => {
  const navigate = useNavigate();
  const { setWhoAmI } = useContext(AuthUserContext);
  const { hotelId } = useParams();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [isSubmittingLogin, setIsSubmittingLogin] = useState(false);

  let loginHeaderForTeam = {
    "Content-Type": "application/json",
    type: "team",
    authorization: "$^tEAm$%^&)%T$%^6^",
    key: "J7TeA^&*mT%j*&^&(E",
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    if (!username.trim()) {
      setError("Email is required");
      return;
    }

    setIsSubmittingLogin(true);
    const ipAddress = await fetch("https://api64.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => data.ip);
    try {
      const response = await fetch(url + teampoints.login, {
        method: "POST",
        headers: loginHeaderForTeam,
        credentials: "include",
        body: JSON.stringify({ email: username, password, ipAddress, hotel: hotelId }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.Status) {
        setShowOtpInput(true);

        const getLocalStorageData = getDecryptedDataFromLocalStorage("type");

        if (getLocalStorageData != null) {
          if (getLocalStorageData) {
            localStorage.clear();
            logoutUser();
          }
        }

        setWhoAmI("team");
        setEncryptedDataToLocalStorage("type", "team");
        navigate(`/hotel/${hotelId}/admin/dashboard`);
      } else {
        setError(data.Message);
      }

      setLoading(false);
    } catch (error) {
      setError("An error occurred while logging in. Please try again.");
    } finally {
      setIsSubmittingLogin(false);
      setLoading(false);
    }
  };

  async function logoutUser() {
    try {
      let response = await fetch(url + clientpoints.logout, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        navigate("/login");
      }
    } catch (error) {
      navigate("/login");
    }
  }

  return (
    <div className='Inclusive-login-page'>
      <div className='login-big-wrapper'>
        <div className='section-wrapper'>
          <Link to='/'>
            <img className='log-inlogo' src='/img/staystation.png' alt='Logo' />{" "}
          </Link>

          <div className='top-login-explain'>
            <h2 className='login-txt'>Team Login</h2>
          </div>

          <form onSubmit={loginHandler}>
            {error && <div className='error_message'>{error}</div>}
            <div className='input-wrapper'>
              <input type='text' required id='username' placeholder='example@gmail.com' onChange={(e) => setUsername(e.target.value)} value={username} tabIndex={1} disabled={showOtpInput} />
              <label htmlFor='username'>Username</label>
            </div>
            <br></br>
            <div className='input-wrapper'>
              <input
                type='password'
                required
                id='password'
                autoComplete='true'
                className='loginPass'
                placeholder='******'
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                tabIndex={2}
                disabled={showOtpInput}
              />
              <label htmlFor='password'>Password</label>
            </div>

            <div>
              <button type='submit' className='subbmitbtn' isabled={isSubmittingLogin}>
                {loading ? <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> : "Submit"}
              </button>
            </div>
          </form>
        </div>
        <div className='login-banner-section '>
          <img src='/img/login2.png' alt='banner' height='100%' className='img-fluid' />
        </div>
      </div>
    </div>
  );
};

export default Component;
