import React, { useEffect, useRef, useState } from "react";
import "./component.css"; // Import your CSS file
import menuData from "../../../../../lib/sidenav.json";
import { useNavigate } from "react-router-dom";

function Component({ name = "Amit StayStation", hotelName = "Coworking StayStation" }) {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  const searchRef = useRef(null);

  const navigate = useNavigate();

  const handleSearch = (e) => {
    const queryTerm = e.target.value;
    setQuery(queryTerm);
    const searchQuery = queryTerm?.trim()?.toLowerCase();

    if (!searchQuery) {
      setResults([]);
      return;
    }

    const wordBoundaryRegex = new RegExp(`\\b${searchQuery}`, "i");
    const filteredResults = [];
    const seen = new Set();

    menuData.forEach((menu) => {
      const parentName = menu.parent.name;

      if (wordBoundaryRegex.test(parentName.toLowerCase())) {
        if (menu.isDropdown) {
          menu.children.forEach((child) => {
            const uniqueKey = `${child.name}_${child.link}`;
            if (!seen.has(uniqueKey)) {
              filteredResults.push({
                name: child.name,
                link: child.link,
                context: parentName,
              });
              seen.add(uniqueKey);
            }
          });
        } else {
          const uniqueKey = `${parentName}_${menu.parent.link}`;
          if (!seen.has(uniqueKey)) {
            filteredResults.push({
              name: parentName,
              link: menu.parent.link,
              context: null,
            });
            seen.add(uniqueKey);
          }
        }
      }

      menu.children.forEach((child) => {
        const childName = child.name;
        const uniqueKey = `${child.name}_${child.link}`;
        if (wordBoundaryRegex.test(childName.toLowerCase()) && !seen.has(uniqueKey)) {
          filteredResults.push({
            name: child.name,
            link: child.link,
            context: parentName,
          });
          seen.add(uniqueKey);
        }
      });
    });

    setResults(filteredResults);
  };

  const handleNavigation = (link) => {
    if (link) {
      navigate(`${link}`);
    }

    setQuery("");
    setResults([]);
  };

  const handleClickOutside = (e) => {
    if (searchRef.current && !searchRef.current.contains(e.target)) {
      setResults([]);
    }
  };

  const handleInputFocus = () => {
    if (query && results.length === 0) {
      handleSearch({ target: { value: query } });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="container1 dashboardfont">
      <div ref={searchRef} className="navbar-form navbar-left" style={{ position: "relative" }}>
        <div className="input-group" id="search_input_with_button">
          <input autocomplete="off" type="text" className="search-inp m-0" placeholder="Search Features" name="search" value={query} onChange={handleSearch} onFocus={handleInputFocus} />
          <button className="btn btn-default" type="submit">
            <i className="ri-search-line" id="search_icon"></i>
          </button>
        </div>
        {query && results.length > 0 && (
          <ul
            style={{
              position: "absolute",
              zIndex: 10000,
              listStyleType: "none",
              marginTop: "5px",
              padding: "0",
              border: "1px solid #ccc",
              borderRadius: "4px",
              width: "400px",
              maxWidth: "500px",
              backgroundColor: "#fff",
              maxHeight: "400px",
              overflowY: "auto",
            }}
          >
            {results.map((result, index) => (
              <li
                key={index}
                style={{
                  padding: "8px",
                  borderBottom: "1px solid #eee",
                  cursor: "pointer",
                }}
                onClick={() => handleNavigation(result.link)}
              >
                {result.context ? `${result.name} in "${result.context}"` : result.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="icons">
        <button className="icon1 helpbot">
          <i className="ri-notification-line"></i>
        </button>
        <button className="square-btn icon"> {hotelName}</button>
        <div className="user-name">
          <div class="hotel-nav2">
            <div className="top-side-border">
              <div className="hotel-user-n1" id="top-nav-username">
                <i class="ri-user-line user-icn-nav"></i>
                <p className="user-txt"> {name}</p>
              </div>
            </div>
          </div>
          {/* <div className='user-n1'>
        <button className="circle-btnname icon">E</button>

          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Component;
