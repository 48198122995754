import React, { useState, useEffect } from "react";
import "./Component.css";
import { useNavigate } from "react-router-dom";

function Component({ data }) {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [characterLimit, setCharacterLimit] = useState(20);
  const navigate = useNavigate();
  const heading = "Landsworth infrastructure";

  useEffect(() => {
    const handleResize = () => {
      // if (window.innerWidth < 676) {
      //   setCharacterLimit(15);
      // } else if (window.innerWidth < 768) {
      //   setCharacterLimit(22);
      // }
      // setIsSmallScreen(window.innerWidth < 768 && window.innerWidth > 576);
      setIsSmallScreen(window.innerWidth < 768);
    };

    handleResize(); // Check on initial render

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function sendToHotel() {
    if (data.urlName != null) {
      navigate(`/hotel/${data.urlName}/admin/dashboard`);
    } else {
      alert("Url is required");
      // navigate(`/space/${data._id}/backend/dashboard`);
    }
  }

  return (
    <div className='col-xl-4 col-lg-6 col-md-6 col-sm-6 col-xs-12 hotel_card'>
      <div className='cabinspace1-card'>
        <div className='row'>
          <div className='col-12'>
            <button className='space-circle-btn-A Aicon'>{data.hotel_name.slice(0, 1)}</button>
            <div className='row'>
              <div className='col-12'>
                <div className='location-top'>
                  <div className='location-top2'>
                    <p className='space1-txt1'>
                      {!isSmallScreen && data.hotel_name.length > characterLimit ? `${data.hotel_name.slice(0, characterLimit)}...` : data.hotel_name}
                      {!isSmallScreen && data.hotel_name.length > characterLimit && <span className='tooltiptext'>{data.hotel_name}</span>}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <p className='space-1-txt'>
                  Your subscription for {data.subscription_rooms} rooms is active, and it will remain valid until {data.subscription_validity}.
                </p>
                <div className='botflx'>
                  <div className=' editicn'>
                    {/* <i class="ri-pencil-line pencil-icn"></i>
              <i class="ri-delete-bin-6-line trash-icn"></i> */}
                  </div>
                  <button className='view-btn-2' onClick={sendToHotel}>
                    Access Dashboard
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Component;
