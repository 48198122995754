import Enquirie from '../../components/enquiries/all_enquirie/table/Component';
import { toast, ToastContainer } from "react-toastify";

export default function Component() {

    return (
        <>
            <ToastContainer />
            <Enquirie name="Quotations" />
        </>
    );
}